import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./componets/common-componets/Header/Header";
import Footer from "./componets/common-componets/Footer/Footer";
import Home from "./componets/Home/Home";
import AboutPage from "./componets/About-Us/About-us";
import Productpage from "./componets/Products/Products";
import Contact from "./componets/Contact-Us/Contact";
import Cookies from "./componets/Cookies/Cookies";
import PrivacyPolicy from "./componets/Privacy-Policy/Privacy";
import Sitemap from "./componets/Site-map/Sitemap";
import Back_to_top from "./componets/Back-To-Top/Back-to-top";
import Notfound from "./componets/Notfound/Notfound";
import Whatsapp from "./componets/Whatsapp/Whatsapp";
import Thankyou from "./componets/Thankyou/Thankyou";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/main.scss';
import Aos from "aos";
import "aos/dist/aos.css";
import UnsbscribeMail from "./componets/UnsubscribeMail/UnsubscribeMail";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import IPInfo from "ip-info-react";
import SubscribeMail from "./componets/SubscribeMail/SubscribeMail";


function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000, // Animation duration
      disable: 'mobile', // Disable animations on mobile
      once: true, // Whether animation should happen only once
    });
    const titleElements = document.querySelectorAll('.animateTitle');

    titleElements.forEach((titleElement) => {
      const text = titleElement.textContent;
      titleElement.innerHTML = '';

      // Split text into spans
      text.split('').forEach((letter) => {
        const span = document.createElement('span');
        span.textContent = letter === ' ' ? '\u00A0' : letter; // Use non-breaking space for blank
        titleElement.appendChild(span);
      });

      const spans = titleElement.querySelectorAll('span');
      let animated = false;

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animated) {
            animated = true; // Ensure the animation runs only once
            spans.forEach((span, index) => {
              setTimeout(() => {
                span.classList.add('show');
              }, index * 100); // Delay for each letter
            });
          }
        });
      });

      observer.observe(titleElement);
    });
  }, []); // Empty array to ensure the effect runs only once after initial render

  const ScrollToTopOnRouteChange = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      Aos.init({ once: true });

      Aos.refresh();
    }, [pathname]);

    return null; // This component doesn't render anything
  };  
  return (
    <div className="App wrapper position-relative">
      <IPInfo>
      <BrowserRouter>
        <ScrollToTopOnRouteChange />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/products" element={<Productpage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/unsbscribeMail/:data" element={<UnsbscribeMail />} />
          <Route path="/subscribeMail/:data" element={<SubscribeMail />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
        <Cookies />
        <Whatsapp />
        <Back_to_top />
        <ToastContainer/>
        <Footer />
      </BrowserRouter>
      </IPInfo>
    </div>
  );
}

export default App;
