import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CookieIcon  } from "../svgIcon/svgIcon";
import { Close  } from "../svgIcon/svgIcon";

import Cookies from "js-cookie"; // Optional: Use js-cookie library for cookie management

function CookiesBanner() {
    const [isBannerHidden, setIsBannerHidden] = useState(false);

    // Check if the cookie is already set when the component mounts
    useEffect(() => {
        const cookiesAllowed = Cookies.get("cookies_allowed");
        if (cookiesAllowed) {
            setIsBannerHidden(true); // Hide banner if cookie is set
        }
    }, []);
    const handleLinkClick = (event) => {
        event.preventDefault(); 
        const targetUrl = event.currentTarget.getAttribute('href');    
        window.location.href = targetUrl; 
      };


    const handleAllowClick = () => {
        // Set cookie when the user allows
        Cookies.set("cookies_allowed", "true", { expires: 365, path: "/" });
        setIsBannerHidden(true); // Hide banner after setting the cookie
        console.log("Cookies allowed and set with js-cookie");
    };

    return (
        <>
            <div className={`ac-cookies ${isBannerHidden ? "d-none" : ""}`} id="js-gdpr-consent-banner">
                <div className="d-flex align-items-center">
                    <span className="d-inline-block mb-2">
                        <CookieIcon className="ac-icon" />
                    </span>
                    <h2 className="h5 d-flex align-items-center ml-3 mb-0">We use cookies</h2>
                    <button type="button" className="clos-btn" title="Close" onClick={handleAllowClick}>
                      <Close className="ac-icon" />
                    </button>
                </div>
                <small>
                   By using our website you agree to our
                   <Link to="/privacy-policy" onClick={handleLinkClick} className="btn-link text-dark ml-1" title="Privacy Policy">
                       <strong>Privacy Policy.</strong>
                   </Link>
                   <button className="btn btn-primary btn-sm py-1 ml-2 allow-btn px-2" title="Allow" onClick={handleAllowClick}>
                       Allow
                   </button>
                </small>
            </div>
        </>
    );
}

export default CookiesBanner;
