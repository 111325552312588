import React from "react";
import { Link } from "react-router-dom";
import { MySvgIcon } from "../svgIcon/svgIcon";
function Whatsapp() {
    return (
        <>
            <Link to="https://wa.me/+919909503377" target="_blank" className="w-icon" title="Chat On: +91 9909503377"><i className="ac-icon" data-icon="cookie"><MySvgIcon /></i></Link>
        </>
    )
}
export default Whatsapp;