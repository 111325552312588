import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import LazyLoad from "react-lazy-load";
import Inner_banner from "../common-componets/Inner-Banner/Inner_banner";
import { GridIcon, ListIcon, ArrowIcon } from "../svgIcon/svgIcon";
import $ from 'jquery';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import showdown from '../../assets/images/shop-down-effect.webp';
import product from '../../assets/images/product-bg-text.webp';
import plant from '../../assets/images/shop-sideimg.webp';
import shope from '../../assets/images/shop-up-effect.webp';
import { Helmet } from "react-helmet";

const user = [{
    id: 0,
    title: 'Black Pepper Whole',
    image: require('../../assets/images/spiceys/black-pepper-whole.webp'),
    text: 'Black pepper, also known as Piper nigrum, is a widely used spice in Indian cuisine. Whole black pepper is known for its sharp, pungent flavor and is used in a range of dishes from curries and stews to rice and lentil dishes. Its ability to enhance flavors and its health benefits make it a crucial ingredient in Indian cooking.',
    producttype: 'spice-pouch',
},
{
    id: 1,
    title: 'Seasame Seeds Hulled Hite',
    image: require('../../assets/images/spiceys/seasame-seeds-hulled-white.webp'),
    text: 'Hulled sesame seeds are a versatile and widely used ingredient in Indian cuisine. They add a mild, nutty flavor and a pleasant texture to both savory and sweet dishes. Their use in traditional sweets and festive foods highlights their cultural significance, while their nutritional benefits, including being a good source of healthy fats, protein, and minerals, make them a valuable addition to the diet. Hulled sesame seeds are a staple in many regional cuisines, appreciated for their flavor, texture, and health benefits.',
    producttype: 'spice-pouch',
},
{
    id: 2,
    title: 'Cardamom Black',
    image: require('../../assets/images/spiceys/cardamom-black.webp'),
    text: 'Black cardamom, also known as badi elachi or dried black cardamom, is a spice commonly used in Indian cuisine for its distinctive flavor and aroma. It is different from green cardamom, which is more frequently used in both sweet and savory dishes.Black cardamom is valued in Indian cuisine for its unique smoky flavor and aromatic qualities. Its ability to enhance the depth and complexity of savory dishes makes it an important spice in Indian cooking. It is especially favored in meat dishes, rice dishes, and spice blends, contributing to the distinctive flavor profile of many traditional Indian recipes.',
    producttype: 'spice-pouch',
},
{
    id: 3,
    title: 'Cinnamon Stick Round',
    image: require('../../assets/images/spiceys/cinnamon-stick-round.webp'),
    text: 'Cinnamon sticks round, or cinnamon quills, add warmth, depth, and a sweet, aromatic flavor to dishes. They are used in curries, rice dishes, spice blends, and desserts, infusing a rich, warm aroma into both savory and sweet recipes.',
    producttype: 'spice-pouch',
},
{
    id: 4,
    title: 'Bay Leaves Whole',
    image: require('../../assets/images/spiceys/bay-leaves-whole.webp'),
    text: 'Bay leaves, known as tej patta in Hindi, are a common aromatic herb used in Indian cooking. They come from the bay laurel tree and have a subtle, slightly floral and herbal flavor that enhances the complexity of many dishes. Bay leaves are valued in Indian cuisine for their ability to subtly enhance the flavor and aroma of a wide range of dishes, making them an essential herb in Indian cooking.',
    producttype: 'spice-pouch',
},
{
    id: 5,
    title: 'Clove Whole',
    image: require('../../assets/images/spiceys/clove-whole.webp'),
    text: 'Clove (Syzygium aromaticum) is a highly aromatic spice used in Indian cuisine for its intense flavor and medicinal properties. It adds depth and complexity to dishes and is valued for both its flavor and health benefits.',
    producttype: 'spice-pouch',
},
{
    id: 6,
    title: 'Fennel Seeds',
    image: require('../../assets/images/spiceys/fennel-seeds.webp'),
    text: 'Fennel seeds are valued for their sweet, aromatic flavor and numerous health benefits. They enhance a wide range of dishes, from curries and gravies to sweets and beverages, adding depth and complexity.',
    producttype: 'spice-pouch',
},
{
    id: 7,
    title: 'Mustard Seeds (Small)',
    image: require('../../assets/images/spiceys/mustard-seeds-small.webp'),
    text: 'Mustard seeds, particularly the small variety, are an essential spice in Indian cooking. They come in different colors, with black and brown mustard seeds being the most commonly used in Indian cuisine.',
    producttype: 'spice-pouch',
},
{
    id: 8,
    title: 'Carrot Pickle',
    image: require('../../assets/images/Pickle-images/carrot_Pickle.webp'),
    text: 'Carrot pickle is a condiment made by mixing chopped carrots with hot oil and ground spices, and adding lemon juice or vinegar for tang.',
    producttype: 'pickle',
},
{
    id: 9,
    title: 'Hot Lemon Pickle',
    image: require('../../assets/images/Pickle-images/hot_Lemon_Pickle.webp'),
    text: 'Hot lemon pickle is a spicy, tangy Indian condiment made with lemons, spices, salt, and sometimes oil. Its a popular condiment that goes well with any Indian meal.',
    producttype: 'pickle',
},
{
    id: 10,
    title: 'Garlic Pickle',
    image: require('../../assets/images/Pickle-images/garlic_Pickle.webp'),
    text: 'Garlic pickle is a spicy condiment made from garlic and a variety of spices. Its a popular pickle in India, and goes well with many Indian dishes like dosa, idli, uttapam, roti, rice, and paratha.',
    producttype: 'pickle',
},
{
    id: 11,
    title: 'Green Chilli Pickle',
    image: require('../../assets/images/Pickle-images/green_Chilli_Pickle.webp'),
    text: 'This Indian Green Chilli Pickle will make your taste buds dance! It is made using green chilli peppers, mustard seeds, salt and mustard oil and has a sure spicy kick. Make this tasty condiment easily and serve as a side dish with your everyday meals.',
    producttype: 'pickle',
},
{
    id: 12,
    title: 'Mango Pickle Hot',
    image: require('../../assets/images/Pickle-images/mango_Pickle_Hot.webp'),
    text: 'Hot mango pickle is a spicy Indian pickle made from raw mangoes and spices. Its a popular condiment that can be eaten as a snack or with meals,',
    producttype: 'pickle',
},
{
    id: 13,
    title: 'Mango Pickle Mild',
    image: require('../../assets/images/Pickle-images/mango_Pickle_Mild.webp'),
    text: 'Mango pickle, also known as aam ka achar, is a traditional Indian condiment made from mangoes, spices, and oil.&nbsp;The flavor balance of , spicy, and tangy is what makes mango pickle so delicious.',
    producttype: 'pickle',
},
{
    id: 14,
    title: 'Mix Vegetable Pickle',
    image: require('../../assets/images/Pickle-images/mix_Vegetable_Pickle.webp'),
    text: 'Mixed vegetable pickle, is a condiment made from a variety of vegetables preserved in spices, oil, and vinegar or lemon juice.&nbsp; Its a versatile condiment that goes well with many dishes, including rice, chapati, sandwiches, and wraps.',
    producttype: 'pickle',
},
{
    id: 15,
    title: 'Sweet Lemon Pickle',
    image: require('../../assets/images/Pickle-images/sweet_Lemon_Pickle.webp'),
    text: 'Sweet lemon pickle, also known as nimbu ka achar, is&nbsp;a traditional Indian condiment made with lemons, spices, salt, Sugar/Jaggery and sometimes oil.&nbsp;Its a good source of vitamin C.It also contains vitamin B6 and dietary fiber, and can help improve metabolism and reduce inflammation.',
    producttype: 'pickle',
},
{
    id: 16,
    title: 'All In One Chutney',
    image: require('../../assets/images/Chutney/All_In_One_Chutney.webp'),
    text: 'This balanced mixture of various ingredients adds flavor to any dish, any snack, any time. All - In - One. Made from Fresh Coriander, Mint leaves, Onion, Green chili &amp; condiments.',
    producttype: 'chutney',
},
{
    id: 17,
    title: 'Coriander Chutney',
    image: require('../../assets/images/Chutney/Coriander-chutney.webp'),
    text: 'Cilantro chutney, also called coriander chutney, is a staple condiment in Indian cuisine. This vibrant blend of coriander, green chilies, cumin seeds, salt, garlic, and ginger offers a spicy, herby, and tangy flavor, making it a perfect accompaniment to nearly any Indian meal.',
    producttype: 'chutney',
},
{
    id: 18,
    title: 'Tamarind Chutney',
    image: require('../../assets/images/Chutney/Date_Tamarind_Chutney.webp'),
    text: 'Tamarind date chutney, also known as imli khajoor chutney, is a spicy, sweet, and tangy sauce perfect for drizzling overtop chaat or serving alongside an Indian snack.',
    producttype: 'chutney',
},
{
    id: 19,
    title: 'Mango Chutney',
    image: require('../../assets/images/Chutney/Mango-Chutney.webp'),
    text: 'Mango chutney is&nbsp;a sweet, sour, and spicy condiment made from mangoes and Indian spices.&nbsp;Its often eaten with roti, chapati, or hot steamed rice, and can also pair well with savory sandwiches or samosas.',
    producttype: 'chutney',
},
{
    id: 20,
    title: 'Thecha Garlic Chutney',
    image: require('../../assets/images/Chutney/Thecha_garlic_Chutney.webp'),
    text: 'Thecha is a spicy, semi-dry, grainy condiment or chutney that originated in the state of Maharashtra, India.&nbsp;Its made with garlic, red chili, and other ingredients, and is often served with bread, roti, paratha, samosas, or chips. Thecha can also be used to make non-vegetarian dishes spicier.',
    producttype: 'chutney',
},
{
    id: 21,
    title: 'Green Chilli Chutney',
    image: require('../../assets/images/Chutney/green_chilli-chutney.webp'),
    text: 'Green chili chutney is a spicy, tangy condiment often used in Indian cuisine. Its made primarily from green chilies, which give it a distinctive heat and vibrant color.',
    producttype: 'chutney',
},
{
    id: 22,
    title: 'Mint Chutney',
    image: require('../../assets/images/Chutney/mint-chutney.webp'),
    text: 'Mint chutney is often served as an accompaniment with a variety of dishes, such as samosas, kebabs, sandwiches, and grilled meats. Its also a popular dip for appetizers and can be used as a flavorful addition to rice or curries.',
    producttype: 'chutney',
},
{
    id: 23,
    title: 'Garlic Paste',
    image: require('../../assets/images/paste/Garlic-Paste.webp'),
    text: 'Garlic paste is a versatile and flavorful ingredient commonly used in cooking to add a concentrated garlic flavor to dishes. Its made by blending garlic cloves with a small amount of oil.Garlic paste can be used in various dishes, including marinades, sauces, soups, and stir-fries.',
    producttype: 'paste',
},
{
    id: 24,
    title: 'Ginger & Garlic Paste',
    image: require('../../assets/images/paste/Ginger-&-Garlic-Paste.webp'),
    text: 'Ginger garlic paste is a staple in many cuisines, especially in Indian cooking. Its a versatile base for a variety of dishes, providing a robust flavor with a blend of spicy ginger and aromatic garlic.This paste is used as a foundational ingredient in many recipes, including curries, marinades, soups, and stir-fries, to add depth and complexity to the flavors.',
    producttype: 'paste',
},
{
    id: 25,
    title: 'Tandoori Paste',
    image: require('../../assets/images/paste/Tandoori-Paste.webp'),
    text: 'Tandoori paste is a flavorful marinade commonly used in Indian cuisine, particularly for tandoori dishes like tandoori chicken or tandoori vegetables. Its known for its vibrant red color and rich, spicy flavor, which comes from a combination of various spices and ingredients.',
    producttype: 'paste',
},
{
    id: 26,
    title: 'Ginger Paste',
    image: require('../../assets/images/paste/Ginger-Paste.webp'),
    text: 'Ginger paste is a staple in many kitchens, particularly in Indian and Asian cuisines. It’s used to add a fresh, zesty, and slightly spicy flavor to a wide range of dishes.Having ginger paste on hand makes it convenient to add a burst of flavor to your dishes quickly',
    producttype: 'paste',
},
{
    id: 27,
    title: 'Biryani Masala',
    image: require('../../assets/images/masala/biryani.webp'),
    text: 'Shahi Biryani Pulav Masala is a quintessential blend that elevates the flavor and aroma of biryanis and pulavs, making it a staple in Indian kitchens. This masala combines a harmonious mix of spices and aromatics essential for creating the complex, layered flavors characteristic of royal and festive rice dishes.',
    producttype: 'masala',
},
{
    id: 28,
    title: 'Chicken Masala',
    image: require('../../assets/images/masala/chicken.webp'),
    text: 'Chicken masala is a versatile and essential spice blend in Indian cuisine. Its rich, aromatic flavor enhances a wide range of chicken dishes, from curries and gravies to marinades and rice dishes, making it a staple in both everyday cooking and special occasions.',
    producttype: 'masala',
},
{
    id: 29,
    title: 'Kasuri Methi',
    image: require('../../assets/images/masala/kasuri-methi.webp'),
    text: 'Kasuri methi (dried fenugreek leaves) is valued for its ability to add a distinctive, aromatic flavor to dishes. It enhances the taste of both vegetarian and non-vegetarian dishes and is an essential component in many traditional spice blends. The pouch packing helps maintain its aroma and flavor, making it a convenient ingredient for home cooks.',
    producttype: 'masala',
},
{
    id: 30,
    title: 'Kitchen King Masala',
    image: require('../../assets/images/masala/kitchen-king.webp'),
    text: 'Kitchen King Masala is a versatile spice blend that brings a rich, well-rounded flavor to a range of dishes. It is appreciated for its ability to simplify seasoning by providing a balanced mix of spices, making it a popular choice in Indian kitchens for enhancing the taste of various recipes.',
    producttype: 'masala',
},
{
    id: 31,
    title: 'Pani Puri',
    image: require('../../assets/images/masala/pani-puri.webp'),
    text: 'Panipuri masala is crucial for creating the authentic taste of Panipuri, giving the flavored water its distinctive tangy and spicy profile. It is a key ingredient in many popular Indian street foods and chaats, making it an essential part of Indian culinary traditions.',
    producttype: 'masala',
},
{
    id: 32,
    title: 'Rajma',
    image: require('../../assets/images/masala/rajma.webp'),
    text: 'A delicious mix of flavourful whole spices. Rajma Masala is used to prepare the rajma curry. Unlock the aromatic treasures of this versatile spice mix that lends an authentic North-Indian flavour to your rajma curry and rice.',
    producttype: 'masala',
},
];

const multiProductOptions = [
    { value: 'spice-pouch', label: 'Spice Pouch' },
    { value: 'pickle', label: 'Pickle' },
    { value: 'chutney', label: 'Chutney' },
    { value: 'paste', label: 'Paste' },
    { value: 'masala', label: 'Masala' },
]

const animatedComponents = makeAnimated();

function Productpage() {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]); // State for category filter
    const [activeButton, setActiveButton] = useState('buttonone');

    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId); // Set the active button based on the one clicked
    };


    // Handle product click and modal open
    const handleProductClick = (item) => {
        setSelectedProduct(item); 
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedProduct(null);
    };

    // Handle category selection
    const handleCategoryClick = (category) => {
        setSelectedCategories((prev) => {
            let newCategories = [...prev];

            if (category === 'all') {
                // Toggle all categories
                if (newCategories.includes('all')) {
                    return []; // Deselect all
                } else {
                    return ['all', 'spice-pouch', 'pickle', 'chutney', 'paste', 'masala']; // Select all categories
                }
            } else {
                // Handle individual category selection
                if (newCategories.includes(category)) {
                    // Deselect specific category
                    newCategories = newCategories.filter((cat) => cat !== category);
                } else {
                    // Add the category to the list
                    newCategories.push(category);
                }

                // Remove "all" if any specific category is selected
                if (newCategories.includes('all')) {
                    newCategories = newCategories.filter((cat) => cat !== 'all');
                }
            }
                        
            // Get the top offset of the #grid-view element
            const gridViewElement = document.getElementById('grid-view');
            if (gridViewElement) {
                const { top } = gridViewElement.getBoundingClientRect();
                // Scroll to the top of the #grid-view element relative to the current scroll position
                window.scrollTo({
                    top: window.scrollY + top - 100, // Current scroll position + element's top offset
                    behavior: 'smooth' // Smooth scrolling
                });
            }

            // Update active button state based on selected categories
            return newCategories;
        });
    };
  
    // Update layout view using jQuery
    useEffect(() => {
        $('#buttonone').click(function () {
            $('.row_shop .shop_cols').addClass("col-md-6 col-lg-6 col-xl-4");
            $('#buttonone').addClass("active");
            $('#buttontwo').removeClass("active");
            $('.shop_product--wrapper').removeClass("list_col--wrapper");
        });

        $('#buttontwo').click(function () {
            $('.row_shop .shop_cols').removeClass("col-md-6 col-lg-6 col-xl-4");
            $('#buttontwo').addClass("active");
            $('#buttonone').removeClass("active");
            $('.shop_product--wrapper').addClass("list_col--wrapper");
        });
    }, []);

    const handleSelectChange = (selectedOptions) => {
        setSelectedCategories(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    return (
        <>
            <Helmet>
                <title>Swadish World Products - Authentic Indian Ingredients & Ready-to-Eat Dishes</title>
                <meta name="description" content="Explore Swadish World's wide range of authentic Indian ingredients, ready-to-eat meals, and cooking essentials. Shop now for high-quality products that bring the taste of India to your home." />
                <meta name="keywords" content="Swadish World products, Indian ingredients, ready-to-eat Indian meals, cooking essentials, authentic Indian food, Indian grocery, buy Indian food online" />
                <meta property="og:title" content="Swadish World Products - Authentic Indian Ingredients & Ready-to-Eat Dishes" />
                <meta property="og:description" content="Shop for authentic Indian ingredients and ready-to-eat meals at Swadish World. Discover quality products that make cooking Indian dishes easy and enjoyable." />
                <meta name="twitter:title" content="Swadish World Products - Authentic Indian Ingredients & Ready-to-Eat Dishes" />
                <meta name="twitter:description" content="Explore Swadish World's selection of authentic Indian ingredients, ready-to-eat meals, and cooking essentials. Shop now and bring the flavors of India to your kitchen." />
            </Helmet>
            <Inner_banner title="Our Products" fs="6vw" top="-58px" />
            <section className="shop_wrapper product-page" id="shop_sec">
                <div className="shop-bg-text position-absolute d-none d-xl-block">
                    <LazyLoad>
                        <img src={product} alt="Products" width="86.39" height="461.75" />
                    </LazyLoad>
                </div>
                <div className="shop_plant--sideimg d-none d-xl-block">
                    <LazyLoad>
                        <img src={plant} alt="plant" width="315" height="403" />
                    </LazyLoad>
                </div>
                <div className="shop_up--effect d-none d-xl-block">
                    <LazyLoad>
                        <img src={shope} alt="effect" width="262" height="267" />
                    </LazyLoad>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3" data-aos="fade-right">
                            <div className="shop_sidebar--wrapper d-none d-lg-block">
                                <aside className="product_widgets">
                                    <h2 className="product_widgets--title">Categories</h2>
                                    <ul className="product_widgets_list--wrapper">
                                        {/* 
                                        {['all', 'spice-pouch', 'pickle', 'chutney', 'paste', 'masala'].map((category) => (
                                            
                                        ))}    
                                        */}
                                        {['spice-pouch', 'pickle', 'chutney', 'paste', 'masala'].map((category) => (                                            
                                            <li className="product_widgets--list" key={category}>
                                                <label className="widget_label" htmlFor={category}>
                                                    {category.charAt(0).toUpperCase() + category.slice(1)}
                                                    <input
                                                        type="checkbox"
                                                        id={category}
                                                        className="product-checkbox"
                                                        checked={selectedCategories.includes(category)}
                                                        onChange={() => handleCategoryClick(category)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </aside>
                            </div>
                        </div>
                        <div className="col-lg-9" data-aos="fade-left">
                            <div className="product_grid--wrapper">
                                <div className="row inner_row flex-wrap">
                                    <div className="col-sm-6">
                                        <div className="product_title--wrapper">
                                            <div className="filter-wrapper d-lg-none">
                                                <div className="form-group">
                                                    <strong className="mb-2 d-block">Select Categories:</strong>
                                                    <Select closeMenuOnSelect={false} components={animatedComponents} isMulti options={multiProductOptions} placeholder="Select Products" onChange={handleSelectChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="select_box--wrapper">
                                            <div className={`grid_box--wrapper ${activeButton === 'buttonone' ? 'active' : ''}`} onClick={() => handleButtonClick('buttonone')} id="buttonone">
                                                <GridIcon />
                                            </div>
                                            <div className={`list_box--wrapper ${activeButton === 'buttontwo' ? 'active' : ''}`} onClick={() => handleButtonClick('buttontwo')} id="buttontwo">
                                                <ListIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content" id="grid-view">
                                <div className="tab-pane fade active show">
                                    <div className="row row_shop">
                                        {/* Filter and map through the products */}
                                        {user.filter((item) => selectedCategories.length === 0 || selectedCategories.includes(item.producttype)).map((item) => (
                                            <div className={`col-12 shop_cols d-flex flex-column ${activeButton === 'buttonone' ? 'col-md-6 col-lg-6 col-xl-4' : ''}`} key={item.id}>
                                                <Link className="product-link" title={item.title} onClick={() => handleProductClick(item)}>
                                                    <div className={`shop_product--wrapper ${activeButton === 'buttontwo' ? 'list_col--wrapper' : ''}`}>
                                                        <div className="shop__product--image">
                                                            <div className="thumbnail-container">
                                                                <div className="thumbnail">
                                                                    <LazyLoad>
                                                                        <img alt={item.title} title={item.title} width="261" height="338" src={item.image} />
                                                                    </LazyLoad>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="product_title--wrapper pb-0">
                                                            <span className="product_title">{item.title}</span>
                                                            <p>{item.text}</p>
                                                        </div>
                                                        <span className="vm-icon">
                                                            <ArrowIcon />
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shop_down--effect d-none d-lg-block">
                    <LazyLoad>
                        <img alt="effect" src={showdown} width="185" height="154" />
                    </LazyLoad>
                </div>
            </section>

            <Modal show={showModal} onHide={handleCloseModal} centered size='xl' className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedProduct?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0 rounded-bottom-3 overflow-hidden modal-category'>
                    {selectedProduct && (

                    <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="shop__product--image">
                                    <div className="thumbnail-container">
                                        <div className="thumbnail">
                                        <LazyLoad>
                                            <img alt={selectedProduct.title} title={selectedProduct.title} width="360" height="338" src={selectedProduct.image} />
                                        </LazyLoad>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex flex-column">
                                <div className="product_title--wrapper flex-grow-1">
                                <p>{selectedProduct.text}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

        </>
    )
}
export default Productpage;