import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/images/f-logo.webp';
import Fbg from '../../../assets/images/footer-bg.webp';
import Netclue from '../../../assets/images/netclues.gif';
import { FacebookIcon, InstagramIcon, TwitterIcon, ArrowIcon } from "../../svgIcon/svgIcon";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularColor from '../../Loader/CircularColor';
import { getBackendUrl } from '../../../common/Common';

const Footer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .lowercase()
        .email('Invalid email format')
        .required('Please enter your email.')
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Please enter a valid email address"
        ),
    }),

    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        await axios.post(`${getBackendUrl()}clientNewsletter`, values);
        setMessage("Thank you, the confirmation request email was sent to your entered address.");
        setMessageType("success");
        resetForm();
        navigate("/thankyou", { state: { fromSubmission: true } });
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setMessage("Email is already subscribed.");
          setMessageType("warning");
        } else {
          setMessage("Something went wrong! Please try again later.");
          setMessageType("error");
          console.error('Error submitting form:', error);
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const location = useLocation();
  const segment = location.pathname.replace('/', '');

  const siteName = 'Swadish World.';

  return (
    <>
      <footer className="main_footer-wrapper">
        <div className="container">
          <div className="row row_top--footer">
            <div className="col-lg-12 col-xl-3 col-xxl-3 d-flex justify-content-between justify-content-xl-start flex-column flex-sm-row flex-xl-column">
              <div className="f-logo d-xl-block d-flex justify-content-between pb-lg-4 flex-wrap" >
                <div className="logo">
                  <Link to="/" title="Swadish Flavours Of India"><img src={Logo} alt="blog" width="270" height="139" /></Link>
                </div>
              </div>
              <ul className="social-icon dark d-flex align-items-center" >
                <li><Link to="https://www.facebook.com/swadishworld/" target="_blank" className="icon facebook" title="Follow Us On: Facebook"><FacebookIcon /></Link></li>
                <li><Link to="https://www.instagram.com/swadishworld" target="_blank" className="icon instagram" title="Follow Us On: Instagram"><InstagramIcon /></Link></li>
                <li><Link to="https://x.com/swadishworld" target="_blank" className="icon twitter" title="Follow Us On: Twitter"><TwitterIcon /></Link></li>
              </ul>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-5" >
              <h2 className="f-title">Useful Links</h2>
              <ul className="menu d-flex flex-wrap d-lg-block scrolltodiv">
                <li className={segment === '' ? 'active' : ''}>
                  <Link to="/" onClick={scrollToTop} title="Home" className={`linked-dropdown ${segment === '' ? 'active' : ''}`} >Home</Link>
                </li>
                <li className={segment === 'about-us' ? 'active' : ''}>
                  <Link to="/about-us" onClick={scrollToTop} className={`about-us ${segment === 'about-us' ? 'active' : ''}`} title="About Us">About Us</Link>
                </li>
                <li className={segment === 'products' ? 'active' : ''}>
                  <Link to="products" onClick={scrollToTop} className={`products ${segment === 'products' ? 'active' : ''}`} title="Products">Products</Link>
                </li>
                <li className={segment === 'contact' ? 'active' : ''}>
                  <Link to="contact" onClick={scrollToTop} className={`contact ${segment === 'contact' ? 'active' : ''}`} title="Contact Us">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-xxl-3 col-xl-3 f-main-bg"  >
              <div className="f-bg position-relative" >
                <img src={Fbg} alt="Footer Image" width="315" height="607" />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-5 col-lg-6">
              <div className="form d-flex justify-content-between flex-column h-100" >
                <div className="top-contant mb-3">
                  <h2 className="f-title">Join our mailing list</h2>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control p-0 bg-transparent border-0 mb-0"
                        name="email"
                        placeholder="Enter Your Email Address*"
                        maxLength="60"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        autoComplete="off"
                        // onPaste={(e) => e.preventDefault()}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="input-group-append">
                        <button
                          className="bg-transparent border-0 btn-submit"
                          type="submit"
                          id="newsletter_btn"
                          title="Subscribe"
                          disabled={loading}
                        >
                          {loading ? <CircularColor /> : <ArrowIcon />}
                        </button>
                      </div>
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <small className="text-danger">{formik.errors.email}</small>
                    ) : null}
                    {message && (
                      <small
                        className={`${messageType === "success" ? "text-success" : "text-danger"}`}
                      >
                        {message}
                      </small>
                    )}
                  </form>
                </div>
                <div className="copyright-section d-flex justify-content-between align-items-end flex-wrap">
                  <div className="left-contant">
                    <p>Copyright © {new Date().getFullYear()} {siteName} All Rights Reserved.</p>
                    <ul className="d-flex flex-wrap">
                      <li className={segment === 'privacy-policy' ? 'active' : ''}>
                        <Link to="/privacy-policy" className="" title="Privacy Policy">Privacy Policy</Link>
                      </li>
                      <li className={segment === 'sitemap' ? 'active' : ''}>
                        <Link to="/sitemap" className="" title="Site Map">Site Map</Link>
                      </li>
                    </ul>
                    <p>Crafted By
                      <Link to="https://www.netclues.com/" className="ms-1 crafted-by" title="Netclues Technologies Pvt. Ltd." rel="nofollow" target="_blank">
                        <img src={Netclue} width="22" height="22" alt='Netclues Technologies Pvt. Ltd.' />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;