import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { decrypt } from "../../common/encryptDecrypt";
import { toast } from "react-toastify";
import { detect } from "detect-browser";
import CircularColor from "../../componets/Loader/CircularColor";

const SubscribeMail = () => {
    const { data } = useParams();
    const decryptEmail = data ? decrypt({ data }) : null;
    const browser = detect();
    const [ip, setIp] = useState("");
    const [country, setCountry] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(null);


    useEffect(() => {
        const fetchIP = async () => {
            try {
                const { data } = await axios.get("https://ipinfo.io/json");
                if (data?.ip) {
                    setIp(data.ip);
                    setCountry(data.country);
                }
            } catch (error) {
                console.error("Error fetching IP:", error.message);
            }
        };

        fetchIP();
    }, []);


    const updateValues = useCallback(
        (values) => {
            return {
                ...values,
                countryName: country || values.countryName,
                ipAddress: ip || values.ipAddress,
            };
        },
        [ip, country]
    );


    useEffect(() => {
        const checkSubscription = async () => {
            if (!decryptEmail || !ip) return;

            const baseValues = {
                email: decryptEmail,
                browserName: browser.name || "",
                osName: browser.os || "",
                ipAddress: ip || "",
                countryName: country || "",
                type: browser.type || "",
            };

            try {
                setLoading(true);
                const updatedValues = updateValues(baseValues);
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}create/newsLetter`,
                    updatedValues
                );

                setIsSubscribed(response.data.isSubscribed);
                toast.success("Successfully subscribed to the newsletter.");
                navigate("/thankyou", { state: { fromSubmission: true } });
            } catch (error) {
                if (error.response?.status === 409) {
                    navigate("/");
                    toast.warning("Email is already subscribed.");
                } else {
                    toast.error("An error occurred! Please try again later.");
                    console.error("Error:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        checkSubscription();
    }, [decryptEmail, ip, updateValues, navigate]);

    useEffect(() => {
        if (isSubscribed === false) {
            navigate("/thankyou", { state: { fromSubmission: true } });
            toast.error("This email is already unsubscribed.");
        }
    }, [isSubscribed, navigate]);


    return (
        <div className="thank-you d-flex flex-column align-items-center justify-content-center">
            {loading ? (
                <CircularColor />
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default SubscribeMail;