import React from 'react';
import Inner_banner from '../common-componets/Inner-Banner/Inner_banner';
import { Link } from 'react-router-dom'; // If you are using React Router for navigation
import { Helmet } from 'react-helmet';

function PrivacyPolicy () {
    return (
        <>
            <Helmet>
                <title>Privacy Policy - Swadish World</title>
                <meta name="description" content="Learn how Swadish World protects your privacy and personal information. Read our comprehensive privacy policy to understand our data collection and security practices." />
                <meta name="keywords" content="Swadish World privacy policy, data protection, personal information, privacy practices, data security, online privacy, user data" />
                <meta property="og:title" content="Privacy Policy - Swadish World" />
                <meta property="og:description" content="Discover how Swadish World handles your personal information and ensures your privacy. Read our privacy policy for details on data protection and security measures." />
                <meta name="twitter:title" content="Privacy Policy - Swadish World" />
                <meta name="twitter:description" content="Learn how Swadish World protects your privacy and personal information. Read our comprehensive privacy policy to understand our data collection and security practices." />
            </Helmet>
            <Inner_banner title="Privacy Policy" fs="6vw" top="-58px" />
            <section className="privacy-policy cms">
                <div className="container" data-aos="fade-up">
                    <p>
                        Swadish is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website,{' '}
                        <Link to="https://www.swadishworld.com" target="_blank" rel="noopener noreferrer">
                            swadishworld.com
                        </Link>
                        , and when you use our services.
                    </p>

                    <h3>Information We Collect:</h3>
                    <ul>
                        <li>
                            <strong>Personal Information:</strong> When you place an order or register on our website, we may collect personal information such as your name, email address, phone number, shipping address, and payment details.
                        </li>
                        <li>
                            <strong>Non-Personal Information:</strong> We may collect non-personal information such as your IP address, browser type, and referring website.
                        </li>
                    </ul>

                    <h3>Use of Information:</h3>
                    <p>
                        We use the information we collect to process your orders, provide customer service, improve our products and services, and personalize your experience. Your information may also be used to communicate with you about promotions, new products, or other updates unless you opt out of such communications.
                    </p>

                    <h3>Sharing of Information:</h3>
                    <p>
                        We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as necessary to fulfill your orders (e.g., shipping partners). We may disclose your information when the law requires or to protect our rights, property, or safety.
                    </p>

                    <h3>Security of Information:</h3>
                    <p>
                        We implement reasonable security measures to protect the confidentiality and integrity of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.
                    </p>

                    <h3>Cookies and Tracking Technologies:</h3>
                    <p>
                        We use cookies and similar tracking technologies to enhance your browsing experience and analyze website traffic. You can control cookies through your browser settings.
                    </p>

                    <h3>Third-Party Links:</h3>
                    <p>
                        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.
                    </p>

                    <h3>Changes to this Privacy Policy:</h3>
                    <p>
                        We may update this Privacy Policy from time to time. The updated version will be posted on this page with an updated effective date.
                    </p>

                    <p>
                        If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at{' '}
                        <Link to="tel:+919909503377" title="Call Us On: +91 9909503377">
                            +91 9909503377
                        </Link>{' '}
                        or{' '}
                        <Link to="mailto:info@swadishworld.com" title="Email Us On: info@swadishworld.com">
                            info@swadishworld.com
                        </Link>.
                    </p>
                    <p>
                        By using{' '}
                        <Link to="https://www.swadishworld.com" target="_blank" rel="noopener noreferrer">
                            swadishworld.com
                        </Link>
                        , you consent to the terms of this Privacy Policy. If you do not agree with the terms of this policy, please do not use our website or provide any personal information.
                    </p>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicy;