import React, { useEffect } from "react";
import { ProgressCircle , TopIcon} from "../svgIcon/svgIcon";

function BackToTop() {
    useEffect(() => {
        "use strict";

        const progressPath = document.querySelector('.progress-wrap path');

        if (progressPath) {
            const pathLength = progressPath.getTotalLength();

            // Set up progress circle path
            progressPath.style.transition = 'none';
            progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
            progressPath.style.strokeDashoffset = `${pathLength}`;
            progressPath.getBoundingClientRect();
            progressPath.style.transition = 'stroke-dashoffset 10ms linear';

            // Update progress on scroll
            const updateProgress = () => {
                const scroll = window.scrollY || document.documentElement.scrollTop;
                const height = document.documentElement.scrollHeight - window.innerHeight;
                const progress = pathLength - (scroll * pathLength / height);
                progressPath.style.strokeDashoffset = `${progress}`;
            };

            // Show progress circle when scrolled down
            const handleScroll = () => {
                const progressWrap = document.querySelector('.progress-wrap');
                if (window.scrollY > 50) {
                    progressWrap.classList.add('active-progress');
                } else {
                    progressWrap.classList.remove('active-progress');
                }
            };

            window.addEventListener('scroll', updateProgress);
            window.addEventListener('scroll', handleScroll);

            // Scroll to top on click
            const progressWrap = document.querySelector('.progress-wrap');
            progressWrap.addEventListener('click', (event) => {
                event.preventDefault();
                window.scrollTo({ top: 0, behavior: "smooth" });
            });

            // Cleanup event listeners on component unmount
            return () => {
                window.removeEventListener('scroll', updateProgress);
                window.removeEventListener('scroll', handleScroll);
                progressWrap.removeEventListener('click', () => {});
            };
        }
    }, []);

    return (
        <div className="progress-wrap active-progress">
            <ProgressCircle />
             <i className="ac-icon" data-icon="top" data-iconwidth="20px" data-iconheight="20px">
            <TopIcon />
        </i>
      </div>
    );
}

export default BackToTop;
