export const CookieIcon = () => (
  <svg 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    width="60" 
    height="60" 
    x="0" 
    y="0" 
    viewBox="0 0 512 512" 
    style={{ enableBackground: 'new 0 0 512 512' }} 
    xmlSpace="preserve"
    className=""
  >
    <path 
      d="M507.44,208.64c-1.296-6.88-6.88-12.096-13.824-12.928c-6.96-0.832-13.6,2.928-16.48,9.312     c-5.072,11.2-16.208,18.992-29.12,18.976c-14.32,0.032-26.416-9.632-30.448-22.896c-2.432-8.096-10.752-12.896-18.976-10.976     C393.536,191.312,388.752,192,384,192c-35.248-0.064-63.936-28.752-64-64c0-4.752,0.688-9.536,1.872-14.576     c1.936-8.224-2.88-16.56-10.976-18.992C297.632,90.416,287.968,78.32,288,64c-0.016-12.928,7.776-24.048,18.976-29.12     c6.384-2.88,10.144-9.536,9.312-16.48c-0.832-6.96-6.048-12.544-12.928-13.84C288.096,1.696,272.288,0,256,0     C114.784,0.032,0.032,114.784,0,256c0.032,141.216,114.784,255.968,256,256c141.216-0.032,255.968-114.784,256-256     C512,239.712,510.304,223.904,507.44,208.64z M414.32,414.32C373.696,454.912,317.792,480,256,480s-117.696-25.088-158.32-65.68     C57.088,373.696,32,317.792,32,256S57.088,138.304,97.68,97.68C138.304,57.088,194.208,32,256,32c2.88,0,5.696,0.304,8.56,0.432     C259.216,41.744,256.016,52.464,256,64c0.032,23.888,13.28,44.368,32.592,55.296C288.288,122.144,288,124.992,288,128     c0.032,52.976,43.024,95.968,96,96c3.008,0,5.856-0.288,8.704-0.592C403.632,242.704,424.096,255.968,448,256     c11.536-0.016,22.256-3.216,31.568-8.56c0.128,2.848,0.432,5.68,0.432,8.56C480,317.792,454.912,373.696,414.32,414.32z"
      fill="#212529"
    />
    <circle cx="192" cy="128" r="32" fill="#212529" />
    <circle cx="128" cy="256" r="32" fill="#212529" />
    <circle cx="288" cy="384" r="32" fill="#212529" />
    <circle cx="272" cy="272" r="16" fill="#212529" />
    <circle cx="400" cy="336" r="16" fill="#212529" />
    <circle cx="176" cy="368" r="16" fill="#212529" />
  </svg>
);

export const ArrowIcon = () => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="14"
  height="11.991"
  viewBox="0 0 14 11.991"
  fill="none" // Default to none to respect SVG's internal colors
>
  <g id="Group_14364" data-name="Group 14364" transform="translate(-4 -6.009)">
    <path
      id="Path_56171"
      data-name="Path 56171"
      d="M18,12a2,2,0,0,0-.59-1.4L13.12,6.3a1,1,0,1,0-1.41,1.42L15,11H5a1,1,0,0,0,0,2H15l-3.29,3.29a1,1,0,1,0,1.41,1.42l4.29-4.3A2,2,0,0,0,18,12Z"
      fill="#1a1a1a"
    />
  </g>
</svg>
);

export const Close = () => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  x="0"
  y="0"
  viewBox="0 0 64 64"
  style={{ enableBackground: 'new 0 0 512 512' }}
  xmlSpace="preserve"
>
  <g>
    <path
      d="M4.59 59.41a2 2 0 0 0 2.83 0L32 34.83l24.59 24.58a2 2 0 0 0 2.83-2.83L34.83 32 59.41 7.41a2 2 0 0 0-2.83-2.83L32 29.17 7.41 4.59a2 2 0 0 0-2.82 2.82L29.17 32 4.59 56.59a2 2 0 0 0 0 2.82z"
      fill="#000000"
      opacity="1"
    />
  </g>
</svg>
);

export const GridIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style={{ width: '24px', height: '24px' }}
    xmlSpace="preserve"
    className=""
  >
    <g>
      <path 
        d="M176.792 0H59.208C26.561 0 0 26.561 0 59.208v117.584C0 209.439 26.561 236 59.208 236h117.584C209.439 236 236 209.439 236 176.792V59.208C236 26.561 209.439 0 176.792 0zM196 176.792c0 10.591-8.617 19.208-19.208 19.208H59.208C48.617 196 40 187.383 40 176.792V59.208C40 48.617 48.617 40 59.208 40h117.584C187.383 40 196 48.617 196 59.208v117.584zM452 0H336c-33.084 0-60 26.916-60 60v116c0 33.084 26.916 60 60 60h116c33.084 0 60-26.916 60-60V60c0-33.084-26.916-60-60-60zm20 176c0 11.028-8.972 20-20 20H336c-11.028 0-20-8.972-20-20V60c0-11.028 8.972-20 20-20h116c11.028 0 20 8.972 20 20v116zM176.792 276H59.208C26.561 276 0 302.561 0 335.208v117.584C0 485.439 26.561 512 59.208 512h117.584C209.439 512 236 485.439 236 452.792V335.208C236 302.561 209.439 276 176.792 276zM196 452.792c0 10.591-8.617 19.208-19.208 19.208H59.208C48.617 472 40 463.383 40 452.792V335.208C40 324.617 48.617 316 59.208 316h117.584c10.591 0 19.208 8.617 19.208 19.208v117.584zM452 276H336c-33.084 0-60 26.916-60 60v116c0 33.084 26.916 60 60 60h116c33.084 0 60-26.916 60-60V336c0-33.084-26.916-60-60-60zm20 176c0 11.028-8.972 20-20 20H336c-11.028 0-20-8.972-20-20V336c0-11.028 8.972-20 20-20h116c11.028 0 20 8.972 20 20v116z"
        fill="#000000"
      />
    </g>
  </svg>
);


export const MenuIcon = () => (
  <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="4" fill="white" />
    <rect y="7" width="20" height="4" fill="white" />
    <rect y="14" width="11" height="4" fill="white" />
  </svg>
);

export default MenuIcon;


export const TopIcon = () => (
  <svg 
    version="1.2" 
    baseProfile="tiny-ps" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 117 63" 
    style={{ width: '20px', height: '20px' }}
  >
    <style></style>
    <path 
      d="M115.3 1.6c1.6 1.6 1.6 4.2-.1 5.8L61.3 61.3c-.8.8-1.9 1.2-2.9 1.2-1.1 0-2.1-.4-2.9-1.2L1.6 7.4C0 5.8 0 3.2 1.6 1.6 3.2 0 5.8 0 7.4 1.6l51.1 51.1 51-51.1c1.6-1.6 4.2-1.6 5.8 0z"
    />
  </svg>
);



export const FacebookIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    width="20" 
    height="20" 
    x="0" 
    y="0" 
    viewBox="0 0 155.139 155.139" 
    style={{ enableBackground: 'new 0 0 512 512' }} 
    xmlSpace="preserve"
    className=""
  >
    <g>
      <path 
        d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z"
        fill="#010002" 
      />
    </g>
  </svg>
);



export const InstagramIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    width="20" 
    height="20" 
    x="0" 
    y="0" 
    viewBox="0 0 511 511.9" 
    style={{ enableBackground: 'new 0 0 512 512' }} 
    xmlSpace="preserve"
    className=""
  >
    <g>
      <path 
        d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0" 
        fill="#000000" 
      />
      <path 
        d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zM423.852 119.3c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0" 
        fill="#000000" 
      />
    </g>
  </svg>
);

export const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    x="0"
    y="0"
    viewBox="0 0 1226.37 1226.37"
    style={{ enableBackground: 'new 0 0 512 512' }}
    xmlSpace="preserve"
    className=""
  >
    <g>
      <path
        d="M727.348 519.284 1174.075 0h-105.86L680.322 450.887 370.513 0H13.185l468.492 681.821L13.185 1226.37h105.866l409.625-476.152 327.181 476.152h357.328L727.322 519.284zM582.35 687.828l-47.468-67.894-377.686-540.24H319.8l304.797 435.991 47.468 67.894 396.2 566.721H905.661L582.35 687.854z"
        fill="#000000"
      />
    </g>
  </svg>
);


export const ListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 60.123 60.123"
    style={{ enableBackground: "new 0 0 512 512" }}
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M57.124 51.893H16.92a3 3 0 1 1 0-6h40.203a3 3 0 0 1 .001 6zM57.124 33.062H16.92a3 3 0 1 1 0-6h40.203a3 3 0 0 1 .001 6zM57.124 14.231H16.92a3 3 0 1 1 0-6h40.203a3 3 0 0 1 .001 6z"
        fill="#000000"
        opacity="1"
      />
      <circle
        cx="4.029"
        cy="11.463"
        r="4.029"
        fill="#000000"
        opacity="1"
      />
      <circle
        cx="4.029"
        cy="30.062"
        r="4.029"
        fill="#000000"
        opacity="1"
      />
      <circle
        cx="4.029"
        cy="48.661"
        r="4.029"
        fill="#000000"
        opacity="1"
      />
    </g>
  </svg>
);


export const MySvgIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 512 512"
    style={{ width: '24px', height: '24px' }}
    fillRule="evenodd"
    
  >
    <g>
      <path
        fill="#39ae41"
        d="M317.12 285.93c-9.69 3.96-15.88 19.13-22.16 26.88-3.22 3.97-7.06 4.59-12.01 2.6-36.37-14.49-64.25-38.76-84.32-72.23-3.4-5.19-2.79-9.29 1.31-14.11 6.06-7.14 13.68-15.25 15.32-24.87 3.64-21.28-24.18-87.29-60.92-57.38C48.62 232.97 330.7 461.46 381.61 337.88c14.4-35.03-48.43-58.53-64.49-51.95zM256 467.28c-37.39 0-74.18-9.94-106.39-28.76-5.17-3.03-11.42-3.83-17.2-2.26l-69.99 19.21 24.38-53.71a22.34 22.34 0 0 0-2.22-22.32C58.5 343.29 44.71 300.61 44.71 256c0-116.51 94.78-211.29 211.29-211.29S467.28 139.49 467.28 256c0 116.5-94.78 211.28-211.28 211.28zM256 0C114.84 0 0 114.84 0 256c0 49.66 14.1 97.35 40.89 138.74L2 480.39a22.37 22.37 0 0 0 3.34 23.76A22.403 22.403 0 0 0 22.36 512c14.42 0 93.05-24.71 113.06-30.2C172.41 501.59 213.9 512 256 512c141.15 0 256-114.85 256-256C512 114.84 397.15 0 256 0z"
      />
      <path
        fill="#00aae1"
        d="M-3363.21-412.9c-70.96-2.28-119.03 37.77-144.21 120.23 34.58-14.87 77.7-15.28 72.37 35.5-2.37 27.49-55.62 128.12-82.74 128.12-33.35 0-54.38-168.76-58.63-195.67-9.21-58.32-33.73-85.56-73.57-81.73-37.2 3.41-118.7 84.62-150.6 112.64l24.1 31.05c22.95-16.06 36.36-24.14 40.17-24.14 65.6 0 64.49 316.97 166.39 316.97 101.61 0 276.65-243.4 281.09-340.54 3.03-66.74-21.76-100.85-74.37-102.43z"
      />
      {/* Add remaining paths here... */}
    </g>
  </svg>
);


export const ProgressCircle = () => {
  return (
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: 'stroke-dashoffset 10ms linear',
          strokeDasharray: '307.919, 307.919',
          strokeDashoffset: '73.0174',
        }}
        fill="none"
        stroke="currentColor" // You can specify a color here
        strokeWidth="2" // Adjust the stroke width as needed
      />
    </svg>
  );
};

