import React from 'react';
import Inner_banner from '../common-componets/Inner-Banner/Inner_banner';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Sitemap() {
    return (
        <>
            <Helmet>
                <title>Swadish World - Your Gateway to Authentic Indian Cuisine</title>
                <meta name="description" content="Discover a world of authentic Indian flavors with Swadish World. From traditional recipes to modern twists, explore our diverse range of delicious Indian dishes and cooking tips." />
                <meta name="keywords" content="Swadish World, authentic Indian cuisine, Indian recipes, Indian food, cooking tips, traditional Indian dishes, modern Indian cooking, Indian meals" />
                <meta property="og:title" content="Swadish World - Your Gateway to Authentic Indian Cuisine" />
                <meta property="og:description" content="Discover a world of authentic Indian flavors with Swadish World. From traditional recipes to modern twists, explore our diverse range of delicious Indian dishes and cooking tips." />
                <meta name="twitter:title" content="Swadish World - Your Gateway to Authentic Indian Cuisine" />
                <meta name="twitter:description" content="Discover a world of authentic Indian flavors with Swadish World. From traditional recipes to modern twists, explore our diverse range of delicious Indian dishes and cooking tips." />
            </Helmet>
        <Inner_banner title="Site Map" fs="7vw" top="-68px"/>
            <section className="sitemap-page inner-page-gap">
                <div className="container" data-aos="fade-up">
                    <div className="sitemap-list">
                        <ul className="d-flex content flex-wrap m-0">
                            <li><Link to="/" title="Home">Home</Link></li>
                            <li><Link to="/about-us" title="About us">About us</Link></li>
                            <li><Link to="/products" title="Products">Products</Link></li>
                            <li><Link to="/contact" title="Contact Us">Contact Us</Link></li>
                            <li><Link to="/privacy-policy" title="Privacy Policy">Privacy Policy</Link></li>
                            <li><Link to="/sitemap.xml" target="_blank" rel="noopener noreferrer" title="XML Site Map">XML Site Map</Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Sitemap;