import React from "react";
import FertilizerBanner from "./Banner/Banner";
import Categories from "./Categories/Categories";
import FindMoreSection from "./Find-More/Findmore";
import Product from "./Our-Product/Product";
import AboutSection from "./About/About";
import { Helmet } from "react-helmet";
function Home(){

    return(
        <>
            <Helmet>
                <title>Swadish World - Your Gateway to Authentic Indian Cuisine</title>
                <meta name="description" content="Discover a world of authentic Indian flavors with Swadish World. From traditional recipes to modern twists, explore our diverse range of delicious Indian dishes and cooking tips." />
                <meta name="keywords" content="Swadish World, authentic Indian cuisine, Indian recipes, Indian food, cooking tips, traditional Indian dishes, modern Indian cooking, Indian meals" />
                <meta property="og:title" content="Swadish World - Your Gateway to Authentic Indian Cuisine" />
                <meta property="og:description" content="Discover a world of authentic Indian flavors with Swadish World. From traditional recipes to modern twists, explore our diverse range of delicious Indian dishes and cooking tips." />
                <meta name="twitter:title" content="Swadish World - Your Gateway to Authentic Indian Cuisine" />
                <meta name="twitter:description" content="Discover a world of authentic Indian flavors with Swadish World. From traditional recipes to modern twists, explore our diverse range of delicious Indian dishes and cooking tips." />
            </Helmet>
            <FertilizerBanner />
            <Categories />
            <FindMoreSection />
            <Product />
            <AboutSection />
        </>
    )
}
export default Home;