import React, { useRef, useState, useEffect } from 'react';
import LazyLoad from "react-lazy-load";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import spiceImage1 from '../../../assets/images/hero-banner/Spices/01.webp';
import spiceImage2 from '../../../assets/images/hero-banner/Spices/02.webp';
import spiceImage3 from '../../../assets/images/hero-banner/Spices/03.webp';
import spiceImage4 from '../../../assets/images/hero-banner/Spices/04.webp';
import Masala1 from '../../../assets/images/hero-banner/Masala/01.webp';
import Masala2 from '../../../assets/images/hero-banner/Masala/02.webp';
import Masala3 from '../../../assets/images/hero-banner/Masala/03.webp';
import Chutney1 from '../../../assets/images/hero-banner/Chutney/01.webp';
import Chutney2 from '../../../assets/images/hero-banner/Chutney/02.webp';
import Chutney3 from '../../../assets/images/hero-banner/Chutney/03.webp';
import Chutney4 from '../../../assets/images/hero-banner/Chutney/04.webp';
import Chutney5 from '../../../assets/images/hero-banner/Chutney/05.webp';
import Paste1 from '../../../assets/images/hero-banner/Paste/01.webp';
import Paste2 from '../../../assets/images/hero-banner/Paste/02.webp';
import Paste3 from '../../../assets/images/hero-banner/Paste/03.webp';
import Paste4 from '../../../assets/images/hero-banner/Paste/04.webp';
import Pickle1 from '../../../assets/images/hero-banner/Pickle/01.webp';
import Pickle2 from '../../../assets/images/hero-banner/Pickle/02.webp';
import Pickle3 from '../../../assets/images/hero-banner/Pickle/03.webp';

const hBannerData = [
  {
    title: 'Spices',
    images: [spiceImage1, spiceImage2, spiceImage3, spiceImage4],
    classes: ['img_scale_in', 'img_move_left', 'img_move_right', 'img_move_up'],
  },
  {
    title: 'Masala',
    images: [Masala1, Masala2, Masala3],
    classes: ['img_move_down', 'img_move_left', 'img_move_up'],
  },
  {
    title: 'Chutney',
    images: [Chutney1, Chutney2, Chutney3, Chutney4, Chutney5],
    classes: ['img_move_down', 'img_move_up', 'img_scale_in', 'img_scale_out', 'img_scale_out'],
  },
  {
    title: 'Paste',
    images: [Paste1, Paste2, Paste3, Paste4],
    classes: ['img_move_up', 'img_move_left', 'img_move_right', 'img_scale_out'],
  },
  {
    title: 'Pickle',
    images: [Pickle1, Pickle2, Pickle3],
    classes: ['img_scale_in', 'img_move_left', 'img_move_right'],
  },
];

function FertilizerBanner() {
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideIntervalRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
    restartAnimations(swiper.slides[swiper.realIndex]);
  };

  const restartAnimations = (currentSlideElement) => {
    const images = currentSlideElement.querySelectorAll('img');
    images.forEach((img) => {
      img.classList.remove('animated');
      void img.offsetWidth;
      img.classList.add('animated');
    });
  };

  useEffect(() => {
    if (swiperRef.current) {
      const initialSlide = swiperRef.current.swiper.slides[currentSlide];
      restartAnimations(initialSlide);
    }
  }, []);

  // const startAutoSlide = () => {
  //   slideIntervalRef.current = setInterval(() => {
  //     goToNextSlide();
  //   }, 5000);
  // };


  const startAutoSlide = () => {
    slideIntervalRef.current = setInterval(() => {
      if (Swiper) { // Check if swiper exists
        goToNextSlide();
      } else {
        console.warn('Swiper instance is null or not initialized');
      }
    }, 5000);
  };




  const pauseAutoSlide = () => {
    clearInterval(slideIntervalRef.current);
  };

  useEffect(() => {
    startAutoSlide();
    return () => {
      pauseAutoSlide();
    };
  }, []);

  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
      restartAutoSlide(); 
    }
  };
  
  const goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
      restartAutoSlide(); 
    }
  };
  
  const restartAutoSlide = () => {
    pauseAutoSlide();  
    startAutoSlide();  
  };

  return (
    <section className="hb-banner">
      <div className="hb-bg"></div>
      <div className="container-fluid" 
           onMouseEnter={pauseAutoSlide} 
           onMouseLeave={startAutoSlide}>
        <Swiper
          ref={swiperRef}
          modules={[EffectFade]}
          slidesPerView={1}
          loop={true}
          lazy={true}
          onSlideChange={handleSlideChange}
          navigation={false}
          effect="fade"
          className="swiper hb-swiper text-white overflow-hidden"
        >
          {hBannerData.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="slider-title">{slide.title}</div>
              <div className="img-container">
                <div className={`img-inner-wrap img-position-0${index + 1}`}>
                  {slide.images.map((image, imgIndex) => (
                    <div key={imgIndex} className={`img-0${imgIndex + 1} img-wrap`}>
                      <img alt={slide.title} className={`${slide.classes[imgIndex]} animated`} src={image} loading="lazy" />
                    </div>
                  ))}
                </div>
              </div>
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination swiper-pagination-fraction d-none d-lg-block">
          <span className="swiper-pagination-current">{currentSlide + 1}</span> /
          <span className="swiper-pagination-total"> {hBannerData.length}</span>
        </div>

        <div className="bannerArrow">
          <button
            className="swiper-button-next"
            onClick={goToNextSlide}
            aria-label="Next slide"
            title="Next"
          >
          </button>
          <button
            className="swiper-button-prev"
            onClick={goToPrevSlide}
            aria-label="Previous slide"
            title="Previous"
          >
          </button>
        </div>
      </div>
    </section>
  );
}

export default FertilizerBanner;
