import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Inner_banner from "../common-componets/Inner-Banner/Inner_banner";
import location from '../../assets/images/location.webp';
import phone from '../../assets/images/phone.webp';
import email from '../../assets/images/email.webp';
import ReCAPTCHA from "react-google-recaptcha";
import LazyLoad from "react-lazy-load";
import shope from '../../assets/images/shop-up-effect.webp';
import showdown from '../../assets/images/shop-down-effect.webp';

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularColor from '../Loader/CircularColor';
import { toast } from "react-toastify";
import { getBackendUrl } from '../../common/Common';
import { detect } from "detect-browser";
import { Helmet } from 'react-helmet';
import { Filter } from 'bad-words';

function Contact() {
    const navigate = useNavigate();
    const browser = detect();
    const [loading, setLoading] = useState(false);
    const [ip, setIp] = useState("");
    const [country, setCountry] = useState("");
    const filter = new Filter();

    useEffect(() => {
        const findIP = async () => {
            try {
                const response = await axios.get("https://ipinfo.io/json");
                if (response?.data) {
                    const result = response.data;
                    setIp(result?.ip);
                    setCountry(result.country);
                    formik.setFieldValue('ipAddress', result?.ip);
                    formik.setFieldValue('countryName', result?.country);
                }
            } catch (error) {
                console.error("Error fetching IP information:", error.message);
            }
        };

        findIP();
    }, []);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            product: '',
            comment: '',
            browserName: browser.name || '',
            osName: browser.os || '',
            ipAddress: ip || '',
            countryName: country || '',
            type: browser.type || '',
            recapcha: ''
        },
        validationSchema: Yup.object({
            name: Yup.string()
            .required('Please enter your name.')
            .test('english-characters-only', 'Name can only contain English characters.', (value) => {
                const nonEnglishPattern = /[^\x00-\x7F]+/;
                return !nonEnglishPattern.test(value);
            })
            .test('no-bad-words', 'Name contains inappropriate content.', (value) => !filter.isProfane(value))
            .test('no-html', 'Name cannot contain HTML or script tags.', (value) => !/<\/?[^>]+(>|$)/.test(value))
            .matches(/^[A-Za-z\s_'`–—.-]+$/, 'Name can only contain alphabetical characters, underscores, spaces, dashes, apostrophes, and dots.')
            .matches(/^[^!@#$%^&*()<>{}[\]\\/+;]+$/, 'Name cannot contain !, @, #, $, %, ^, &, *, (, ), <, >, {, }, [, ], \\, /, +, or ; characters.'),
            email: Yup.string()
                .trim()
                .lowercase()
                .email('Invalid email format')
                .required('Please enter your email.'),
            mobile: Yup.string()
                .required('Please enter your mobile number.')
                .matches(/^[0-9+\-\(\)\s]+$/, 'Please enter a valid mobile number')
                .test('min-digits', 'Mobile number must be at least 10 digits', (value) => value.replace(/\D/g, '').length >= 10)
                .test('max-digits', 'Mobile number must not exceed 20 digits', (value) => value.replace(/\D/g, '').length <= 20)
                .test('not-all-zeros', 'Mobile number cannot be all zeroes', (value) => value.replace(/\D/g, '') !== '0'.repeat(value.replace(/\D/g, '').length))
                .test('not-all-zeros-after-country-code', 'Mobile number cannot be all zeros after the country code', (value) => {
                    const digits = value.replace(/\D/g, '');
                    const countryCodeLength = value.startsWith('+') ? value.split(/[\s\(\)\-]/)[0].length : 0; // Calculate country code length only if it starts with '+'
                    const numberPart = digits.slice(countryCodeLength);
                    
                    return numberPart !== '' && numberPart !== '0'.repeat(numberPart.length);
                  }),
            product: Yup.string()
                .required('Please enter your product.')
                .test('english-characters-only', 'Product can only contain English characters.', (value) => {
                    const nonEnglishPattern = /[^\x00-\x7F]+/;
                    return !nonEnglishPattern.test(value);
                })
                .test('no-bad-words', 'Product contains inappropriate content.', (value) => !filter.isProfane(value))
                .test('no-url', 'URLs are not allowed.', (value) => !/(https?:\/\/[^\s]+)/.test(value))
                .test('no-html', 'Product cannot contain HTML or script tags.', (value) => !/<\/?[^>]+(>|$)/.test(value)),
            comment: Yup.string()
                .test('no-url', 'URLs are not allowed.', (value) => !/(https?:\/\/[^\s]+)/.test(value))
                .test('no-bad-words', 'Comment contains inappropriate content.', (value) => !filter.isProfane(value))
                .test('no-html', 'Comment cannot contain HTML or script tags.', (value) => !/<\/?[^>]+(>|$)/.test(value))
                .matches(/^[A-Za-z0-9\s.,!?$&()":%'*#@]+$/, 'Comment can only contain English characters, numbers, spaces, and the special characters @ # ? . , " " \' \' ! \' $ & ( ) : % *'),
            recapcha: Yup.string().required("Please select I'm not a robot.")
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {
                await axios.post(`${getBackendUrl()}create/contactUs`, values);
                resetForm();
                navigate("/thankyou", { state: { fromSubmission: true } });
            } catch (error) {
                toast.error("Something went wrong! Please try after some time.");
                console.error('Error submitting form:', error);
            } finally {
                setLoading(false);
            }
        },
    });

    const handleRecaptchaChange = (value) => {
        formik.setFieldValue("recapcha", value);
    };

    return (
        <>
            <Helmet>
                <title>Contact Us - Swadish World | Authentic Indian Cuisine</title>
                <meta name="description" content="Get in touch with Swadish World for any queries, feedback, or assistance. We're here to help you discover and enjoy authentic Indian cuisine." />
                <meta name="keywords" content="Contact Swadish World, customer service, Indian cuisine inquiries, Swadish feedback, food delivery support, Swadish customer support" />
                <meta property="og:title" content="Contact Us - Swadish World | Authentic Indian Cuisine" />
                <meta property="og:description" content="Reach out to Swadish World with your queries or feedback. We’re here to assist you with anything related to our authentic Indian cuisine and services." />
                <meta name="twitter:title" content="Contact Us - Swadish World | Authentic Indian Cuisine" />
                <meta name="twitter:description" content="Get in touch with Swadish World for any queries, feedback, or assistance. We're here to help you discover and enjoy authentic Indian cuisine." />
            </Helmet>
            <Inner_banner title="Contact Us" fs="7vw" top="-68px" />
            <section className="contact-us position-relative">
                <div className="shop_up--effect d-none d-lg-block">
                    <LazyLoad>
                        <img src={shope} alt="effect" width="262" height="267" />
                    </LazyLoad>
                </div>
                <div className="contact-mail-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" data-aos="fade-right">
                                <div className="map-class">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.051522839579!2d72.5349322753138!3d23.02188037917419!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84606d232851%3A0xc5c64db3565e9695!2sNetclues%20India!5e0!3m2!1sen!2sin!4v1723534471389!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='location'></iframe>
                                </div>
                            </div>
                            <div className="col-md-6 c-details">
                                <div className="contact-text" data-aos="fade-up">
                                    <h2 className="cm-title">Get in Touch</h2>
                                    <p>We’re here to help! If you have any questions, feedback, or need assistance, please don’t hesitate to reach out to us.</p>
                                </div>
                                <div className="contact-info">
                                    <div className="c-box" data-aos="fade-right" data-aos-delay="200">
                                        <div className="contact-box-img">
                                            <LazyLoad>
                                                <img src={location} alt="Location" width="24" height="24" />
                                            </LazyLoad>
                                        </div>
                                        <div className="align-self-center">
                                            <p>1320, Stratum @ Venus Grounds, Surendra Mangaldas Rd, Niyojan Nagar, Ambawadi Jhansi Ki Rani, Surendra mangaldas road, Rd, Nehru Nagar, Satellite, Ahmedabad, Gujarat 380015</p>
                                        </div>
                                    </div>
                                    <div className="c-box" data-aos="fade-right" data-aos-delay="300">
                                        <div className="contact-box-img">
                                            <LazyLoad>
                                                <img src={phone} alt="Call" width="24" height="24" />
                                            </LazyLoad>
                                        </div>
                                        <div className="align-self-center">
                                            <Link to="tel:+919909503377" title="Call Us On: +91 9909503377">+91 9909503377</Link>
                                        </div>
                                    </div>
                                    <div className="c-box" data-aos="fade-right" data-aos-delay="400">
                                        <div className="contact-box-img">
                                            <LazyLoad>
                                                <img src={email} alt="Email" width="24" height="24" />
                                            </LazyLoad>
                                        </div>
                                        <div className="align-self-center">
                                            <Link to="mailto:info@swadishworld.com" title="Email Us On: info@swadishworld.com">info@swadishworld.com</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-us-form">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-12">
                                <h2>Leave a message</h2>
                                <p>We love to hear from you</p>
                                <form className="form--wrapper" method="POST" id="contactUSForm" name="contactUSForm" onSubmit={formik.handleSubmit}>
                                    <div className="form-row form_input_row">
                                        <div className="form-group col-md-6">
                                            <input
                                                type="text"
                                                className={`form-control mb-0 ${formik.touched.name && formik.errors.name ? "is-invalid" : ""}`}
                                                placeholder="Name*"
                                                id="name"
                                                name="name"
                                                maxLength="50"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                            {formik.touched.name && formik.errors.name && <div className="invalid-feedback">{formik.errors.name}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input
                                                type="tel"
                                                className={`form-control mb-0 ${formik.touched.mobile && formik.errors.mobile ? "is-invalid" : ""}`}
                                                placeholder="Mobile Number*"
                                                id="mobile"
                                                name="mobile"
                                                maxLength="20"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile}
                                            />
                                            {formik.touched.mobile && formik.errors.mobile && <div className="invalid-feedback">{formik.errors.mobile}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input
                                                type="email"
                                                className={`form-control mb-0 ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
                                                placeholder="Email*"
                                                id="email"
                                                name="email"
                                                maxLength="70"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                            />
                                            {formik.touched.email && formik.errors.email && <div className="invalid-feedback">{formik.errors.email}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input
                                                type="text"
                                                className={`form-control mb-0 ${formik.touched.product && formik.errors.product ? "is-invalid" : ""}`}
                                                placeholder="Product*"
                                                id="product"
                                                name="product"
                                                maxLength="50"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.product}
                                            />
                                            {formik.touched.product && formik.errors.product && <div className="invalid-feedback">{formik.errors.product}</div>}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <textarea
                                                className={`form-control mb-0 ${formik.touched.comment && formik.errors.comment ? "is-invalid" : ""}`}
                                                placeholder="Comments"
                                                rows="5"
                                                id="comment"
                                                name="comment"
                                                maxLength="500"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.comment}
                                            />
                                            {formik.touched.comment && formik.errors.comment && <div className="invalid-feedback">{formik.errors.comment}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <div className="captcha">
                                                <ReCAPTCHA
                                                    sitekey="6LeUUD0qAAAAAKM7jSHK2r4rMzCQgWxDOmvQQKGl"
                                                    onChange={handleRecaptchaChange}
                                                />
                                                {formik.touched.recapcha && formik.errors.recapcha && <div className="text-danger">{formik.errors.recapcha}</div>}
                                            </div>
                                        </div>
                                        <div className="btn--wrapper col-md-6 d-flex justify-content-md-end align-items-center">
                                            <button
                                                type="submit"
                                                id="contact_submit"
                                                className="btn d-flex justify-content-center align-items-center"
                                                disabled={loading}
                                                style={{ position: 'relative', padding: '10px 20px' }}
                                            >
                                                {loading ? <CircularColor /> : 'Submit'}
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shop_down--effect d-none d-lg-block">
                    <LazyLoad>
                        <img alt="effect" src={showdown} width="185" height="154" />
                    </LazyLoad>
                </div>
            </section>
        </>
    );
}
export default Contact;