import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
function Notfound() {
    return (
        <>
            <section className="not-found-section mh-100 min-vh-100 d-flex align-items-center text-center">
                <div className="container position-relative">
                    <div className="number" data-aos="fade">404</div>
                    <h1 className="main-title text-white" data-aos="fade">Page Not Found</h1>
                    <p></p>
                    <Link to="/" className="btn btn-primary">Back To Home</Link>
                </div>
            </section>
        </>
    )
}
export default Notfound;