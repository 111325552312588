function FindMoreSection() {
    return (
        <section className="find-more_sec position-relative" id="contactUs" data-aos="fade">
            <div className="ani-container position-absolute overflow-hidden">
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
                <div className="firefly position-absolute"></div>
            </div>
            <div className="container-fluid p-0">
                <div className="content text-center">
                    <h2 className="cm-title animateTitle mb-3">Find Out More</h2>
                    <p>To explore our diverse product range or schedule a meeting to stock a specific category, reach out to us today. </p>
                </div>
            </div>
        </section>
    );
};

export default FindMoreSection;