import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { Modal } from 'react-bootstrap';
import LazyLoad from 'react-lazy-load';
import bgVector from '../../../assets/images/bg-vector.webp';
import categoryBgVector from '../../../assets/images/our-category-bg-vactor.webp';
import categories from '../../../assets/images/Categories.webp';

function FertilizerSection() {
	useEffect(() => {
		AOS.init({ duration: 0 }); // Initialize AOS for animations
	}, []);

	const fertilizerTypes = [
		{
		  id: 1,
		  image: require('../../../assets/images/black-pepper-whole.webp'),
		  title: 'Spices',
		  description: 'Spice up your dishes with the finest flavors from the vibrant states of India.',
		  delay: '0'
		},
		{
		  id: 2,
		  image: require('../../../assets/images/Green_Chilli_Pickle.webp'),
		  title: 'Pickle',
		  description: 'From sweet to spicy, explore a world of versatile flavors with our pickles.',
		  delay: '200'
		},
		{
		  id: 3,
		  image: require('../../../assets/images/date_tamarind_Chutney.webp'),
		  title: 'Chutney',
		  description: 'Transform every bite into a taste sensation with our savory chutneys.',
		  delay: '400'
		},
		{
		  id: 4,
		  image: require('../../../assets/images/ginger-paste.webp'),
		  title: 'Paste',
		  description: 'Enhance your cooking effortlessly with our convenient & flavorful pastes.',
		  delay: '600'
		},
		{
		  id: 5,
		  image: require('../../../assets/images/rajma.webp'),
		  title: 'Masala',
		  description: 'Discover the secret to authentic, aromatic dishes with our masala blends.',
		  delay: '800'
		}
	];

	const [selectedProduct, setSelectedProduct] = useState(null);
	const [showModal, setShowModal] = useState(false);

	// Function to handle the click event and open the modal with the product details
	const handleProductClick = (item) => {
		setSelectedProduct(item); // Store the selected product in state
		setShowModal(true); // Open the modal
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedProduct(null); // Reset selected product when modal closes
	};

	return (
		<>
			<section className="fertilizer_type--wrapper">
				<div className="our-category-bg position-absolute">
					<img src={bgVector} alt="Background Vector" width="78" height="131" />
				</div>
				<div className="our-category-bg-vactor position-absolute">
					<img src={categoryBgVector} alt="Category Background Vector" width="152" height="196" />
				</div>
				<div className="our-category-bg-text position-absolute">
					<img src={categories} alt="Categories Background" width="140" height="849" />
				</div>
				<div className="container">
					<h2 className="cm-title mb-0 animateTitle">Our Categories</h2>
					<div className="h5 mt-3 text-center">Embark on a flavor-filled adventure through India's best with our vast categories.</div>
					<div className="row">
						<div className="col-12">
							<div className="type_of_fertilizer--wrapper">
								{fertilizerTypes.map((item, index) => (
									<div className="fertilizer_type--item" key={index}>
										{/* Removed href attribute to prevent default behavior */}
										<div
											onClick={() => handleProductClick(item)}
											className="c-product-link"
											role="button" // Accessibility: make it act like a button
											title={item.title}
											aria-label={`View details about ${item.title}`}
										>
											<div className="fertilizer_img--wrapper" data-aos={index % 2 === 0 ? "fade-up" : "fade-down"} data-aos-delay={item.delay}>
												<div className="thumbnail-container">
													<div className="thumbnail">
														<LazyLoad>
															<img src={item.image} alt={item.title} title={item.title} width="188" height="266" />
														</LazyLoad>
													</div>
													<div className="fertilizer_name--ecllipse title">{item.title}</div>
												</div>
											</div>
											<span className="fertilizer_type--details desc">{item.description}</span>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Modal for showing product details */}
			<Modal show={showModal} onHide={handleCloseModal} centered size='xl' className="custom-modal">
				<Modal.Header closeButton>
					<Modal.Title>{selectedProduct?.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body className='p-0 rounded-bottom-3 overflow-hidden modal-category'>
					{selectedProduct && (


						<div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="shop__product--image">
                                    <div className="thumbnail-container">
                                        <div className="thumbnail">
                                            <LazyLoad>
												<img alt={selectedProduct.title} title={selectedProduct.title} src={selectedProduct.image} />
                                            </LazyLoad>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex flex-column">
                                <div className="product_title--wrapper flex-grow-1">
								<p>{selectedProduct.description}</p>
                                </div>
                            </div>
                        </div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}

export default FertilizerSection;
