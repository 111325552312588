import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CircularColor from "../Loader/CircularColor";
import { decrypt } from "../../common/encryptDecrypt";
import { toast } from "react-toastify";

const UnsbscribeMail = () => {
    const { data } = useParams();
    const decryptEmail = data ? decrypt({ data: data }) : null;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(null);

    useEffect(() => {
        if (isSubscribed === false) {
            navigate("/");
            toast.error("This email is already unsubscribed.");
        }
    }, [isSubscribed, navigate]);

    useEffect(() => {
        const checkSubscription = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}checkSubscription`,
                    { email: decryptEmail }
                );
                setIsSubscribed(response.data.isSubscribed);
            } catch (error) {
                console.error("Error checking subscription:", error);
            }
        };
        if (decryptEmail) {
            checkSubscription();
        }
    }, [decryptEmail]);

    const handleUnsubscribe = async () => {
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}unsubscribedMail`,
                { email: decryptEmail }
            );
            toast.success(
                "You have successfully un-subscribed from the newsletter."
            );
            navigate("/");
        } catch (error) {
            toast.error("Something went wrong! Please try again later.");
            console.error("Error submitting form:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="thank-you d-flex flex-column align-items-center justify-content-center">
            {loading ? (
                <CircularColor />
            ) : isSubscribed ? (
                <>
                    <h2 className="mt-4 mt-xl-5">You can unsubscribe right here if you want to stop getting updates</h2>
                    <button className="btn btn-primary mt-4 mt-xl-5 mb-xl-5" onClick={handleUnsubscribe}> Un-Subscribe </button>                
                </>
            ) : (
                navigate("/")
            )}
        </div>
    );
};
export default UnsbscribeMail;