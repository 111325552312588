import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import { useNavigate, useLocation } from "react-router-dom";

function Thankyou() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.state?.fromSubmission) {
            navigate("/");
        } else {
            window.history.replaceState({}, document.title);
        }
    }, [location, navigate]);
    
    return (
        <>
        <section class="thank-you text-center inner-pages border-bottom border-dark">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-12 col-md-10">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-12">
                                <div class="image w-75 mb-4 mx-auto">
                                    <div class="thumbnail-container" data-aos="fade">
                                        <div class="thumbnail">
                                            <LazyLoad>
                                                <img alt="" title="" width="540" height="486" src={require('../../assets/images/thankyou.webp')} />
                                            </LazyLoad>
                                        </div>
                                    </div>
                                </div>
                                <div class="content">
                                    <h2 class="cm-title mb-2 mb-lg-3 text-dark text animateTitle">Thank You</h2>
                                    <p class="mb-2 mb-lg-3 ">We have received your request. We will get back to you shortly.</p>
                                    <h4 class="mb-4 mb-lg-4 mb-xl-5">Have A Great Day!</h4>
                                    {/* <a href="/" class="btn btn-primary" title="Back To Home">Back To Home</a> */}
                                    <Link to="/" className='btn btn-primary' title="Back To Home">Back To Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>            
        </>
    )
}
export default Thankyou;