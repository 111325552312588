import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure CSS is imported
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { ArrowIcon } from '../../svgIcon/svgIcon';
import LazyLoad from 'react-lazy-load';
import product from '../../../assets/images/product-bg-text.webp';
import plant from '../../../assets/images/shop-sideimg.webp';
import shope from '../../../assets/images/shop-up-effect.webp';
import down from '../../../assets/images/shop-down-effect.webp';
const SpicePouch1 = [
    { 
        id: 1,
        image: require('../../../assets/images/spiceys/black-pepper-whole.webp'), 
        title: "Black Pepper Whole", 
        description: "Black pepper, also known as Piper nigrum, is a widely used spice in Indian cuisine. Whole black pepper is known for its sharp, pungent flavor and is used in a range of dishes from curries and stews to rice and lentil dishes. Its ability to enhance flavors and its health benefits make it a crucial ingredient in Indian cooking." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/spiceys/seasame-seeds-hulled-white.webp'), 
        title: "Seasame Seeds Hulled White", 
        description: "Hulled sesame seeds are a versatile and widely used ingredient in Indian cuisine. They add a mild, nutty flavor and a pleasant texture to both savory and sweet dishes. Their use in traditional sweets and festive foods highlights their cultural significance, while their nutritional benefits, including being a good source of healthy fats, protein, and minerals, make them a valuable addition to the diet. Hulled sesame seeds are a staple in many regional cuisines, appreciated for their flavor, texture, and health benefits." 
    },
    { 
        id: 3,
        image: require('../../../assets/images/spiceys/cardamom-black.webp'),
        title: "Cardamom Black", 
        description: "Black cardamom, also known as badi elachi or dried black cardamom, is a spice commonly used in Indian cuisine for its distinctive flavor and aroma. It is different from green cardamom, which is more frequently used in both sweet and savory dishes. Black cardamom is valued in Indian cuisine for its unique smoky flavor and aromatic qualities. Its ability to enhance the depth and complexity of savory dishes makes it an important spice in Indian cooking. It is especially favored in meat dishes, rice dishes, and spice blends, contributing to the distinctive flavor profile of many traditional Indian recipes." 
    },
];

const SpicePouch2 = [
    { 
        id: 1,
        image: require('../../../assets/images/spiceys/cinnamon-stick-round.webp'),
        title: "Cinnamon Stick Round", 
        description: "Cinnamon sticks round, or cinnamon quills, add warmth, depth, and a sweet, aromatic flavor to dishes. They are used in curries, rice dishes, spice blends, and desserts, infusing a rich, warm aroma into both savory and sweet recipes." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/spiceys/bay-leaves-whole.webp'),
        title: "Bay Leaves Whole", 
        description: "Bay leaves, known as tej patta in Hindi, are a common aromatic herb used in Indian cooking. They come from the bay laurel tree and have a subtle, slightly floral and herbal flavor that enhances the complexity of many dishes. Bay leaves are valued in Indian cuisine for their ability to subtly enhance the flavor and aroma of a wide range of dishes, making them an essential herb in Indian cooking." 
    },
    { 
        id: 3,
        image: require('../../../assets/images/spiceys/clove-whole.webp'),
        title: "Clove Whole", 
        description: "Clove (Syzygium aromaticum) is a highly aromatic spice used in Indian cuisine for its intense flavor and medicinal properties. It adds depth and complexity to dishes and is valued for both its flavor and health benefits." 
    },
];

const SpicePouch3 = [
    { 
        id: 1,
        image: require('../../../assets/images/spiceys/fennel-seeds.webp'),
        title: "Fennel Seeds", 
        description: "Fennel seeds are valued for their sweet, aromatic flavor and numerous health benefits. They enhance a wide range of dishes, from curries and gravies to sweets and beverages, adding depth and complexity."
    },
    { 
        id: 2,
        image: require('../../../assets/images/spiceys/mustard-seeds-small.webp'), 
        title: "Mustard Seeds (Small)", 
        description: "Mustard seeds, particularly the small variety, are an essential spice in Indian cooking. They come in different colors, with black and brown mustard seeds being the most commonly used in Indian cuisine."
    },
];

const Pickle1 = [
    { 
        id: 1,
        image: require('../../../assets/images/Pickle-images/carrot_Pickle.webp'), 
        title: "Carrot Pickle", 
        description: "Carrot pickle is a condiment made by mixing chopped carrots with hot oil and ground spices, and adding lemon juice or vinegar for tang." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/Pickle-images/hot_Lemon_Pickle.webp'), 
        title: "Hot Lemon Pickle", 
        description: "Hot lemon pickle is a spicy, tangy Indian condiment made with lemons, spices, salt, and sometimes oil. It's a popular condiment that goes well with any Indian meal." 
    },
    { 
        id: 3,
        image: require('../../../assets/images/Pickle-images/garlic_Pickle.webp'), 
        title: "Garlic Pickle", 
        description: "Garlic pickle is a spicy condiment made from garlic and a variety of spices. It's a popular pickle in India, and goes well with many Indian dishes like dosa, idli, uttapam, roti, rice, and paratha." 
    },
];

const Pickle2 = [
    { 
        id: 1,
        image: require('../../../assets/images/Pickle-images/green_Chilli_Pickle.webp'), 
        title: "Green Chilli Pickle", 
        description: "This Indian Green Chilli Pickle will make your taste buds dance! It is made using green chilli peppers, mustard seeds, salt and mustard oil and has a sure spicy kick. Make this tasty condiment easily and serve as a side dish with your everyday meals." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/Pickle-images/mango_Pickle_Hot.webp'), 
        title: "Mango Pickle Hot", 
        description: "Hot mango pickle is a spicy Indian pickle made from raw mangoes and spices. It's a popular condiment that can be eaten as a snack or with meals." 
    },
    { 
        id: 3,
        image: require('../../../assets/images/Pickle-images/mango_Pickle_Mild.webp'), 
        title: "Mango Pickle Mild", 
        description: "Mango pickle, also known as aam ka achar, is a traditional Indian condiment made from mangoes, spices, and oil. The flavor balance of spicy and tangy is what makes mango pickle so delicious." 
    }
];

const Pickle3 = [
    { 
        id: 1,
        image: require('../../../assets/images/Pickle-images/mix_Vegetable_Pickle.webp'), 
        title: "Mix Vegetable Pickle", 
        description: "Mixed vegetable pickle, is a condiment made from a variety of vegetables preserved in spices, oil, and vinegar or lemon juice. It's a versatile condiment that goes well with many dishes, including rice, chapati, sandwiches, and wraps." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/Pickle-images/sweet_Lemon_Pickle.webp'), 
        title: "Sweet Lemon Pickle",
        description: "Sweet lemon pickle, also known as nimbu ka achar, is a traditional Indian condiment made with lemons, spices, salt, Sugar/Jaggery and sometimes oil. It's a good source of vitamin C. It also contains vitamin B6 and dietary fiber, and can help improve metabolism and reduce inflammation." 
    },
];

const Chutney1 = [
    { 
        id: 1,
        image: require('../../../assets/images/Chutney/All_In_One_Chutney.webp'), 
        title: "All In One Chutney", 
        description: "This balanced mixture of various ingredients adds flavor to any dish, any snack, any time. All - In - One. Made from Fresh Coriander, Mint leaves, Onion, Green chili & condiments." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/Chutney/Coriander-chutney.webp'), 
        title: "Coriander Chutney", 
        description: "Coriander chutney is a spicy, tangy Indian condiment made with fresh coriander, spices, and sometimes lemon or yogurt. It's a popular condiment that enhances many Indian meals." 
    },
];

const Chutney2 = [
    { 
        id: 1,
        image: require('../../../assets/images/Chutney/Date_Tamarind_Chutney.webp'), 
        title: "Date Tamarind Chutney", 
        description: "Tamarind date chutney, also known as imli khajoor chutney, is a spicy, sweet, and tangy sauce perfect for drizzling overtop chaat or serving alongside an Indian snack." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/Chutney/Mango-Chutney.webp'), 
        title: "Mango Chutney", 
        description: "Mango chutney is a sweet, sour, and spicy condiment made from mangoes and Indian spices. It's often eaten with roti, chapati, or hot steamed rice, and can also pair well with savory sandwiches or samosas." 
    },
    { 
        id: 3,
        image: require('../../../assets/images/Chutney/Thecha_garlic_Chutney.webp'), 
        title: "Thecha Garlic Chutney", 
        description: "Thecha is a spicy, semi-dry, grainy condiment or chutney that originated in the state of Maharashtra, India. It's made with garlic, red chili, and other ingredients, and is often served with bread, roti, paratha, samosas, or chips. Thecha can also be used to make non-vegetarian dishes spicier." 
    },
];

const Chutney3 = [
    { 
        id: 1,
        image: require('../../../assets/images/Chutney/green_chilli-chutney.webp'), 
        title: "Green Chilli Chutney", 
        description: "Green chili chutney is a spicy, tangy condiment often used in Indian cuisine. It's made primarily from green chilies, which give it a distinctive heat and vibrant color." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/Chutney/mint-chutney.webp'), 
        title: "Mint Chutney", 
        description: "Mint chutney is often served as an accompaniment with a variety of dishes, such as samosas, kebabs, sandwiches, and grilled meats. It's also a popular dip for appetizers and can be used as a flavorful addition to rice or curries." 
    },
];

const Paste1 = [
    { 
        id: 1,
        image: require('../../../assets/images/paste/Garlic-Paste.webp'), 
        title: "Garlic Paste", 
        description: "Garlic paste is a versatile and flavorful ingredient commonly used in cooking to add a concentrated garlic flavor to dishes. It's made by blending garlic cloves with a small amount of oil. Garlic paste can be used in various dishes, including marinades, sauces, soups, and stir-fries." 
    },
];
const Paste2 = [
    { 
        id: 1,
        image: require('../../../assets/images/paste/Ginger-&-Garlic-Paste.webp'), 
        title: "Ginger Garlic Paste", 
        description: "Ginger garlic paste is a staple in many cuisines, especially in Indian cooking. It's a versatile base for a variety of dishes, providing a robust flavor with a blend of spicy ginger and aromatic garlic. This paste is used as a foundational ingredient in many recipes, including curries, marinades, soups, and stir-fries, to add depth and complexity to the flavors." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/paste/Tandoori-Paste.webp'),
        title: "Tandoori Paste", 
        description: "Tandoori paste is a flavorful marinade commonly used in Indian cuisine, particularly for tandoori dishes like tandoori chicken or tandoori vegetables. It's known for its vibrant red color and rich, spicy flavor, which comes from a combination of various spices and ingredients." 
    },
];
const Paste3 = [
    { 
        id: 1,
        image: require('../../../assets/images/ginger-paste.webp'), 
        title: "Ginger Paste", 
        description: "Ginger paste is a staple in many kitchens, particularly in Indian and Asian cuisines. It’s used to add a fresh, zesty, and slightly spicy flavor to a wide range of dishes. Having ginger paste on hand makes it convenient to add a burst of flavor to your dishes quickly." 
    },
];

const Masala1 = [
    { 
        id: 1,
        image: require('../../../assets/images/masala/biryani.webp'), 
        title: "Biryani", 
        description: "Shahi Biryani Pulav Masala is a quintessential blend that elevates the flavor and aroma of biryanis and pulavs, making it a staple in Indian kitchens. This masala combines a harmonious mix of spices and aromatics essential for creating the complex, layered flavors characteristic of royal and festive rice dishes." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/masala/chicken.webp'), 
        title: "Chicken", 
        description: "Chicken masala is a versatile and essential spice blend in Indian cuisine. Its rich, aromatic flavor enhances a wide range of chicken dishes, from curries and gravies to marinades and rice dishes, making it a staple in both everyday cooking and special occasions." 
    },
];
const Masala2 = [
    { 
        id: 1,
        image: require('../../../assets/images/masala/kasuri-methi.webp'), 
        title: "Kasuri Methi", 
        description: "Kasuri methi (dried fenugreek leaves) is valued for its ability to add a distinctive, aromatic flavor to dishes. It enhances the taste of both vegetarian and non-vegetarian dishes and is an essential component in many traditional spice blends. The pouch packing helps maintain its aroma and flavor, making it a convenient ingredient for home cooks." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/masala/kitchen-king.webp'), 
        title: "Kitchen King", 
        description: "Kitchen King Masala is a versatile spice blend that brings a rich, well-rounded flavor to a range of dishes. It is appreciated for its ability to simplify seasoning by providing a balanced mix of spices, making it a popular choice in Indian kitchens for enhancing the taste of various recipes." 
    },
];
const Masala3 = [
    { 
        id: 1,
        image: require('../../../assets/images/masala/pani-puri.webp'), 
        title: "Pani Puri", 
        description: "Panipuri masala is crucial for creating the authentic taste of Panipuri, giving the flavored water its distinctive tangy and spicy profile. It is a key ingredient in many popular Indian street foods and chaats, making it an essential part of Indian culinary traditions." 
    },
    { 
        id: 2,
        image: require('../../../assets/images/masala/rajma.webp'), 
        title: "Rajma", 
        description: "A delicious mix of flavourful whole spices. Rajma Masala is used to prepare the rajma curry. Unlock the aromatic treasures of this versatile spice mix that lends an authentic North-Indian flavour to your rajma curry and rice." 
    },
];

function Product() {
    const [activeTab, setActiveTab] = useState('v-pills-featured');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
    const handleProductClick = (item) => {
        setSelectedProduct(item);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedProduct(null);
    };
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://code.jquery.com/jquery-3.6.0.min.js";
        script.onload = () => {
            const bootstrapScript = document.createElement('script');
            document.body.appendChild(bootstrapScript);
        };
        document.body.appendChild(script);
    }, []);

    const handleLinkClick = (event) => {
        event.preventDefault(); 
        const targetUrl = event.currentTarget.getAttribute('href');    
        window.location.href = targetUrl; 
      };

    const ProductCard = ({ product }) => (
        <div>
            <div className="shop__product--image" onClick={() => handleProductClick(product)}>
                <div className="thumbnail-container">
                    <div className="thumbnail">
                        <LazyLoad>
                            <img src={product.image} alt={product.title} title={product.title} width="294" height="294" />
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <div className="product_title--wrapper">
                <span className="product_title">{product.title}</span>
                <div className="desc d-none">{product.description}</div>
            </div>
        </div>
    );
    return (
        <>
            <section className="shop_wrapper" id="shop_sec">
                <div className="shop-bg-text position-absolute">
                    <img src={product} alt="Products" width="86.39" height="461.75" />
                </div>
                <div className="shop_plant--sideimg d-none d-md-block" >
                    <LazyLoad>
                        <img src={plant} alt="plant" width="315" height="403" />
                    </LazyLoad>
                </div>
                <div className="shop_up--effect">
                    <LazyLoad>
                        <img src={shope} alt="effect" width="262" height="267" />
                    </LazyLoad>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="shop_title--wrapper" data-aos="fade-up">
                                <h2 className="text-left cm-title"><span className='animateTitle'>Our</span><br /><span className='animateTitle'>Products</span></h2>
                                <div className="h5 mt-3">Journey through India's flavors with our authentic products.</div>
                            </div>
                            <div className="nav shop_tab mt-5" id="v-pills-tab" role="tablist" aria-orientation="vertical" data-aos="fade-up">
                                <Link className={`nav-link ${activeTab === 'v-pills-featured' ? 'active' : ''}`} onClick={() => handleTabClick('v-pills-featured')} to="#v-pills-featured" role="tab">Spice Pouch</Link>
                                <Link className={`nav-link ${activeTab === 'v-pills-deals' ? 'active' : ''}`} onClick={() => handleTabClick('v-pills-deals')} to="#v-pills-deals" role="tab">Pickle</Link>
                                <Link className={`nav-link ${activeTab === 'v-pills-arrival' ? 'active' : ''}`} onClick={() => handleTabClick('v-pills-arrival')} to="#v-pills-arrival" role="tab">Chutney</Link>
                                <Link className={`nav-link ${activeTab === 'v-pills-paste' ? 'active' : ''}`} onClick={() => handleTabClick('v-pills-paste')} to="#v-pills-paste" role="tab">Paste</Link>
                                <Link className={`nav-link ${activeTab === 'v-pills-masala' ? 'active' : ''}`} onClick={() => handleTabClick('v-pills-masala')} to="#v-pills-masala" role="tab">Masala</Link>
                                <Link className="nav-link " to="/products" onClick={handleLinkClick}>View All Product <ArrowIcon /></Link>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="v-pills-tabContent" data-aos="fade">
                                <div className={`tab-pane fade ${activeTab === 'v-pills-featured' ? 'active show' : ''}`} id="v-pills-featured" role="tabpanel">
                                    <div className="row row_shop">
                                        <div className="col-md-6 col-lg-6 col-xl-4 shop_cols">
                                            {SpicePouch1.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-4 shop_cols">
                                            {SpicePouch2.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-4 shop_cols">
                                            <div className="row">
                                                {SpicePouch3.map((product) => (
                                                    <div className="col-12 col-md-6 col-xl-12" key={product.id}>
                                                        <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails">
                                                            <ProductCard product={product} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Repeat for other sections like 'v-pills-deals', 'v-pills-arrival', etc. */}
                                
                                <div className={`tab-pane fade ${activeTab === 'v-pills-deals' ? 'active show' : ''}`} id="v-pills-deals" role="tabpanel">
                                    <div className="row row_shop">
                                        <div className="col-md-6 col-lg-6 col-xl-4 shop_cols">
                                            {Pickle1.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-4 shop_cols">
                                            {Pickle2.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-4 shop_cols">
                                            <div className="row">
                                                {Pickle3.map((product) => (
                                                    <div className="col-12 col-md-6 col-xl-12" key={product.id}>
                                                        <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails">
                                                            <ProductCard product={product} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Similar updates for 'v-pills-arrival', 'v-pills-paste', and 'v-pills-masala' */}
                                
                                <div className={`tab-pane fade ${activeTab === 'v-pills-arrival' ? 'active show' : ''}`} id="v-pills-arrival" role="tabpanel">
                                    <div className="row row_shop">
                                        <div className="col-md-6 col-lg-6 col-xl-4 order-0 order-xl-0 shop_cols">
                                            {Chutney1.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-4 order-2 order-xl-1 shop_cols">
                                            <div className="row">
                                                {Chutney2.map((product) => (
                                                    <div className="col-12 col-md-6 col-xl-12" key={product.id}>
                                                        <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails">
                                                            <ProductCard product={product} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-4 order-1 order-xl-2 shop_cols">
                                            {Chutney3.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Continue with similar updates for Paste and Masala sections */}
                                
                                <div className={`tab-pane fade ${activeTab === 'v-pills-paste' ? 'active show' : ''}`} id="v-pills-paste" role="tabpanel">
                                    <div className="row row_shop">
                                        <div className="col-md-6 col-lg-6 col-xl-4 order-0 order-xl-0 shop_cols">
                                            {Paste1.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-4 order-2 order-xl-1 shop_cols">
                                            <div className="row">
                                                {Paste2.map((product) => (
                                                    <div className="col-12 col-md-6 col-xl-12" key={product.id}>
                                                        <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails">
                                                            <ProductCard product={product} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-4 order-1 order-xl-2 shop_cols">
                                            {Paste3.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className={`tab-pane fade ${activeTab === 'v-pills-masala' ? 'active show' : ''}`} id="v-pills-masala" role="tabpanel">
                                    <div className="row row_shop">
                                        <div className="col-md-6 col-lg-6 col-xl-4 shop_cols">
                                            {Masala1.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-4 shop_cols">
                                            {Masala2.map((product) => (
                                                <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails" key={product.id}>
                                                    <ProductCard product={product} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-4 shop_cols">
                                            <div className="row">
                                                {Masala3.map((product) => (
                                                    <div className="col-12 col-md-6 col-xl-12" key={product.id}>
                                                        <div className="shop_product--wrapper h-product-link" data-toggle="modal" data-target="#productdetails">
                                                            <ProductCard product={product} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="shop_down--effect">
                    <LazyLoad>
                        <img alt="effect" width="185" height="154" src={down} />
                    </LazyLoad>
                </div>
            </section>
            <Modal show={showModal} onHide={handleCloseModal} centered size='xl' className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedProduct?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0 rounded-bottom-3 overflow-hidden modal-category'>
                    {selectedProduct && (
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="shop__product--image">
                                    <div className="thumbnail-container">
                                        <div className="thumbnail">
                                            <LazyLoad>
                                                <img alt={selectedProduct.title} title={selectedProduct.title} width="360" height="338" src={selectedProduct.image} />
                                            </LazyLoad>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex flex-column">
                                <div className="product_title--wrapper flex-grow-1">
                                    <p>{selectedProduct.description}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};
 
export default Product;