import React from 'react'; 
function AboutSection() {
    return (
        <section className="about_sec" data-aos="fade">
            <div className="container-fluid">
                <div className="content text-center">
                    <h1 className="cm-title animateTitle">Live Life Full with Swadish</h1>
                    <p className="text-white">
                        The name “Swadish” is derived from the Hindi word “Swadish”, translating to delicious and wholesome, which is the ultimate compliment a dish can earn. At Swadish, we aim to bring authentic flavors and spices of India to
                        your kitchen and infuse goodness and wholesomeness into your recipes, making every dish “Swadish.”
                    </p>
                </div>
            </div>
        </section>
    );
};
 
export default AboutSection;